import React from "react";
import { Pagination } from "antd";

export default function CustomPagination(props) {
  const { total, onPageChange, page } = props;

  return (
    <>
      {page ? (
        <Pagination
          className="mt-3"
          showSizeChanger
          showTotal={(total, range) => (
            <div>
              Showing <strong>{range[0]}</strong> to <strong>{range[1]}</strong>{" "}
              of <strong>{total}</strong> results
            </div>
          )}
          onChange={onPageChange}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          defaultPageSize={10}
          defaultCurrent={1}
          current={page}
          total={total || 1}
        />
      ) : (
        <Pagination
          className="mt-3"
          showSizeChanger
          showTotal={(total, range) => (
            <div>
              Showing <strong>{range[0]}</strong> to <strong>{range[1]}</strong>{" "}
              of <strong>{total}</strong> results
            </div>
          )}
          onChange={onPageChange}
          pageSizeOptions={["10", "20", "30", "40", "50"]}
          defaultPageSize={10}
          defaultCurrent={1}
          total={total || 1}
        />
      )}
    </>
  );
}
