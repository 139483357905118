import React from "react";
import { Layout } from "antd";
import logo from "../../assets/icons/Logo.svg";
const { Content, Header } = Layout;

export default function AuthLayout({ children }) {
  return (
    <>
      <Layout className="h-screen">
        <Header className="bg-white flex justify-between items-center">
          <div className="cursor-pointer">
            <img
              src={logo}
              alt="CEO SUITE"
              style={{ width: 150, height: 100 }}
            />
          </div>
        </Header>
        <Layout>
          <Content className="bg-custom overflow-y-auto">{children}</Content>
        </Layout>
      </Layout>
    </>
  );
}
