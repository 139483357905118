import React, { useRef, useState, useEffect } from "react";
import { Input, Select, Col, Row, Form, notification, DatePicker } from "antd";
import { Trash3Fill } from "react-bootstrap-icons";
import dayjs from "dayjs";
import {
  ButtonBack,
  Buttons,
  FieldButton,
} from "../../../components/atoms/index";
import { getLabel } from "../../../utils/validateMessage";
import validateMessage from "../../../utils/validateMessage";
import DiscountDetail from "./DiscountDetail";
import {
  postDiscount,
  putDiscount,
  getOfficeIDAndName,
} from "../../../services/api";
import { formatRupiah } from "../../../utils/Helper";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function FormSection(props) {
  const { setSection, section, setTrigger, trigger, childData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [officeID, setOfficeID] = useState([]);
  const NUMBER_VALIDATOR = /^[0-9]*$/;
  const promoType = Form.useWatch("type", form);

  const [selectedOffices, setSelectedOffices] = useState([]);
  const [addOffices, setAddOffices] = useState([]);
  const [addQuantity, setAddQuantity] = useState([]);
  const [addBuyX, setAddBuyX] = useState([]);
  const [addGetX, setAddGetX] = useState([]);

  // Handle Period
  const [startDate, setStartDate] = useState(childData.start_date);
  const [endDate, setEndDate] = useState(null);
  const handleStartDate = (start_date) => {
    setStartDate(start_date);
  };
  const handleEndDate = (end_date) => {
    setEndDate(end_date);
  };

  const [minimumBuyX, setMinimumBuyX] = useState(
    section === "edit" ? childData.buy_x : 0
  );
  const [maximumGetX, setMaximumGetX] = useState(
    section === "edit" ? childData.get_x : 0
  );

  const [inputMinimumBuyX, setInputMinimumBuyX] = useState({
    status: "success",
    message: "",
    value: childData.buy_x !== undefined ? childData.buy_x : "",
  });
  const [inputMaximumGetX, setInputMaximumGetX] = useState({
    status: "success",
    message: "",
    value: childData.get_x !== undefined ? childData.get_x : "",
  });

  const handleInputNumber = (event, setInputAmount, setPriceFunction) => {
    const realtimeValue = Number(event.target.value.replace(/[^0-9]/g, ""));
    setPriceFunction(realtimeValue);
    if (!NUMBER_VALIDATOR.test(realtimeValue)) {
      setInputAmount({
        status: "error",
        message: "Please only enter numbers!",
        value: realtimeValue,
      });
    } else if (event.target.value === "") {
      setInputAmount({
        status: "error",
        message: "Required!",
        value: event.target.value,
      });
    } else {
      setInputAmount({
        status: "success",
        message: "",
        value: realtimeValue,
      });
    }
  };

  const handleAddOffice = () => {
    setAddOffices([...addOffices, null]);
    setAddQuantity([...addQuantity, null]);
    setAddBuyX([...addBuyX, null]);
    setAddGetX([...addGetX, null]);
  };

  const handleRemoveOffice = (index) => {
    const updatedAddOffices = [...addOffices];
    updatedAddOffices.splice(index, 1);
    setAddOffices(updatedAddOffices);

    const updatedAddQuantity = [...addQuantity];
    updatedAddQuantity.splice(index, 1);
    setAddQuantity(updatedAddQuantity);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    fetchOfficeID();
  }, []);

  const fetchOfficeID = () => {
    getOfficeIDAndName()
      .then((officeData) => {
        setOfficeID(officeData);
      })
      .catch((err) => {
        console.error("Error fetching offices:", err);
      });
  };

  const onFinish = (values) => {
    const mergedOfficeID = [values.office_id].concat(addOffices);
    const mergedQuantity = [parseInt(values.quantity)].concat(
      addQuantity.map((qty) => parseInt(qty))
    );
    const mergedBuyX = [parseInt(inputMinimumBuyX.value)].concat(
      addBuyX.map((qty) => parseInt(qty))
    );
    const mergedGetX = [parseInt(inputMaximumGetX.value)].concat(
      addGetX.map((qty) => parseInt(qty))
    );
    const result = {
      ...values,
      name: values.name,
      type: values.type,
      start_date: values.start_date,
      end_date: values.end_date,
      amount: parseInt(values.amount),
      office_id: mergedOfficeID,
      quantity: mergedQuantity,
    };
    if (
      inputMinimumBuyX.value !== undefined &&
      inputMinimumBuyX.value !== "" &&
      inputMaximumGetX.value !== undefined &&
      inputMaximumGetX.value !== ""
    ) {
      result.buy_x = mergedBuyX;
      result.get_x = mergedGetX;
    }
    // delete result.period;

    if (section === "add") {
      console.log("form add", result);
      setLoading(true);
      postDiscount(result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully added discount",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to add discount!",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (section === "edit") {
      console.log("form edit", result);
      setLoading(true);
      putDiscount(childData.id, result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully edit discount!",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to edit discount!",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // list ref
  const DataRef = useRef();
  const OfficeDataRef = useRef();

  useEffect(() => {
    if (section === "edit" && childData) {
      // const startDate = moment(childData.start_date);
      // const endDate = moment(childData.end_date);
      // const period = [startDate, endDate];
      console.log(moment(childData.end_date, "YYYY-MM-DDTHH:mm:ss"));
      form.setFieldsValue({
        ...childData,
        start_date: dayjs(childData.start_date),
        end_date: dayjs(childData.end_date),
        type: childData.type,
        amount: childData.amount,
      });

      const initialOffices = [];
      const initialQuantity = [];
      const initialBuyX = [];
      const initialGetX = [];
      childData.offices.forEach((office) => {
        initialOffices.push(office.office_id);
        initialQuantity.push(office.quantity);
        initialBuyX.push(office.buy_x || null);
        initialGetX.push(office.get_x || null);
      });
      setAddOffices(initialOffices);
      setAddQuantity(initialQuantity);
      setAddBuyX(initialBuyX);
      setAddGetX(initialGetX);
    }
  }, [section, form]);

  const promoOptions = [
    {
      id: 1,
      value: "direct_discount",
      label: "Direct Discount",
    },
    {
      id: 2,
      value: "buy_x_get_x",
      label: "Buy X get X",
    },
  ];

  const handleSearchOffice = (value) => {
    const filteredOffices = officeID.filter((office) =>
      office.name.toLowerCase().includes(value.toLowerCase())
    );
    setOfficeID(filteredOffices);
  };

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add Discount"}
              {section === "view" && "Detail"}
              {section === "edit" && "Edit Discount"}
            </div>
          </div>
        </div>
        <div className="body-with-footer">
          <div className="body-content pb-0">
            {section !== "view" ? (
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateMessages={validateMessage}
              >
                <div className="relative scroll-custom">
                  <div ref={DataRef} className="fit-scroll py-2">
                    <div className="text-lg font-semibold text-primary mb-2">
                      Discount Data
                    </div>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Promo Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Insert promo name"
                            disabled={section === "view"}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Start Date"
                          name="start_date"
                          rules={[
                            {
                              required: true,
                              message: "Please select promo period!",
                            },
                          ]}
                        >
                          <DatePicker
                            showTime
                            // format="DD MMM YYYY HH:mm:ss"
                            className="w-full"
                            disabled={section === "view"}
                            onChange={handleStartDate}
                            value={startDate ? dayjs(startDate) : null}
                          />
                        </Form.Item>
                        {/* Percentage */}
                        {["direct_discount"].includes(promoType) && (
                          <>
                            <Form.Item
                              label="Percentage"
                              name="amount"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input
                                addonBefore="%"
                                placeholder="Insert promo presentage"
                                disabled={section === "view"}
                                type="number"
                              />
                            </Form.Item>
                          </>
                        )}
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Promo Type"
                          name="type"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Choose promo type"
                            disabled={section === "view"}
                          >
                            {promoOptions?.map((item) => (
                              <Option key={item.id} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="End Date"
                          name="end_date"
                          rules={[
                            {
                              required: true,
                              message: "Select end date promo period",
                            },
                          ]}
                        >
                          <DatePicker
                            showTime
                            format="DD MMM YYYY HH:mm:ss"
                            className="w-full"
                            disabled={section === "view"}
                            onChange={handleEndDate}
                            value={endDate ? dayjs(endDate) : null}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <div>
                      <div className="text-md font-medium text-primary py-2 mt-3">
                        Select office to discount
                      </div>
                      {section !== "edit" && (
                        <div className="items-center p-4 shadow-md rounded-md mb-6">
                          <Row gutter={16}>
                            <Col span={11}>
                              <Form.Item
                                label="Choose office to discount"
                                name="office_id"
                              >
                                <Select
                                  showSearch
                                  placeholder="Choose office"
                                  optionFilterProp="children"
                                  onSearch={handleSearchOffice}
                                  disabled={section === "view"}
                                  value={selectedOffices}
                                  onChange={(value, option) =>
                                    setSelectedOffices(value)
                                  }
                                >
                                  {officeID.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              {promoType === "buy_x_get_x" && (
                                <Form.Item
                                  label={getLabel("Minimum Buy X", true)}
                                  validateStatus={inputMinimumBuyX.status}
                                  help={inputMinimumBuyX.message}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                    {
                                      message: validateMessage.number.range,
                                    },
                                  ]}
                                >
                                  <Input
                                    onChange={(e) =>
                                      handleInputNumber(
                                        e,
                                        setInputMinimumBuyX,
                                        setMinimumBuyX
                                      )
                                    }
                                    value={
                                      minimumBuyX === null ||
                                      minimumBuyX === undefined ||
                                      minimumBuyX === 0
                                        ? ""
                                        : formatRupiah(minimumBuyX)
                                    }
                                    id="buy_x"
                                    placeholder="Insert minimum buy per-month"
                                  />
                                </Form.Item>
                              )}
                            </Col>
                            <Col span={11}>
                              <Form.Item
                                label="Promo Quota"
                                name="quantity"
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Insert promo quota"
                                  disabled={section === "view"}
                                  type="number"
                                />
                              </Form.Item>
                              {promoType === "buy_x_get_x" && (
                                <Form.Item
                                  label={getLabel("Maximum Get X", true)}
                                  validateStatus={inputMaximumGetX.status}
                                  help={inputMaximumGetX.message}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                    {
                                      message: validateMessage.number.range,
                                    },
                                  ]}
                                >
                                  <Input
                                    onChange={(e) =>
                                      handleInputNumber(
                                        e,
                                        setInputMaximumGetX,
                                        setMaximumGetX
                                      )
                                    }
                                    value={
                                      maximumGetX === null ||
                                      maximumGetX === undefined ||
                                      maximumGetX === 0
                                        ? ""
                                        : formatRupiah(maximumGetX)
                                    }
                                    id="get_x"
                                    placeholder="Insert maximum user can get (per-month)"
                                  />
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                        </div>
                      )}
                      {/* Data office tambahan */}
                      {addOffices.map((office, index) => (
                        <div
                          key={index}
                          className="items-center p-4 shadow-md rounded-md mb-6"
                        >
                          <Row gutter={16}>
                            <Col span={11}>
                              <Form.Item label="Choose office to discount">
                                <Select
                                  showSearch
                                  placeholder="Choose office"
                                  optionFilterProp="children"
                                  onSearch={handleSearchOffice}
                                  value={office || undefined}
                                  onChange={(value) => {
                                    const updatedAddOffices = [...addOffices];
                                    updatedAddOffices[index] = value;
                                    setAddOffices(updatedAddOffices);
                                  }}
                                >
                                  {officeID.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              {promoType === "buy_x_get_x" && (
                                <Form.Item
                                  label={getLabel("Minimum Buy X", true)}
                                  validateStatus={inputMinimumBuyX.status}
                                  help={inputMinimumBuyX.message}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                    {
                                      message: validateMessage.number.range,
                                    },
                                  ]}
                                >
                                  <Input
                                    onChange={(e) => {
                                      const updatedAddBuyX = [...addBuyX];
                                      updatedAddBuyX[index] = e.target.value;
                                      setAddBuyX(updatedAddBuyX);
                                    }}
                                    value={
                                      addBuyX[index] === null ||
                                      addBuyX[index] === undefined ||
                                      addBuyX[index] === 0
                                        ? ""
                                        : formatRupiah(addBuyX[index])
                                    }
                                    id={`buy_x_${index}`}
                                    placeholder="Insert minimum buy per-month"
                                  />
                                </Form.Item>
                              )}
                            </Col>
                            <Col span={11}>
                              <Form.Item
                                label="Promo Quota"
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Input
                                  onChange={(e) => {
                                    const updatedAddQuantity = [...addQuantity];
                                    updatedAddQuantity[index] = e.target.value;
                                    setAddQuantity(updatedAddQuantity);
                                  }}
                                  placeholder="Insert promo quota"
                                  disabled={section === "view"}
                                  type="number"
                                  value={addQuantity[index]}
                                />
                              </Form.Item>
                              {promoType === "buy_x_get_x" && (
                                <Form.Item
                                  label={getLabel("Maximum Get X", true)}
                                  validateStatus={inputMaximumGetX.status}
                                  help={inputMaximumGetX.message}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                    {
                                      message: validateMessage.number.range,
                                    },
                                  ]}
                                >
                                  <Input
                                    onChange={(e) => {
                                      const updatedAddGetX = [...addGetX];
                                      updatedAddGetX[index] = e.target.value;
                                      setAddGetX(updatedAddGetX);
                                    }}
                                    value={
                                      addGetX[index] === null ||
                                      addGetX[index] === undefined ||
                                      addGetX[index] === 0
                                        ? ""
                                        : formatRupiah(addGetX[index])
                                    }
                                    id={`get_x_${index}`}
                                    placeholder="Insert maximum user can get (per-month)"
                                  />
                                </Form.Item>
                              )}
                            </Col>
                            <Col span={2} className="flex items-center">
                              <Buttons
                                className="py-1 px-2 rounded-lg hover:border-primary"
                                icon={
                                  <Trash3Fill className="text-gray-600 hover:text-primary" />
                                }
                                onClick={() => handleRemoveOffice(index)}
                                disabled={section === "view"}
                              />
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <FieldButton
                        onClick={handleAddOffice}
                        disabled={section === "view"}
                        title="Add more offices"
                      />
                    </div>
                  </div>
                </div>
                {section !== "view" && (
                  <div className="footer">
                    <Buttons onClick={() => setSection("default")}>
                      Cancel
                    </Buttons>
                    <Form.Item type="button">
                      <Buttons
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Save
                      </Buttons>
                    </Form.Item>
                  </div>
                )}
              </Form>
            ) : (
              <DiscountDetail
                {...props}
                DataRef={DataRef}
                OfficeDataRef={OfficeDataRef}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
