import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import transactionIcon from "../../assets/icons/transaction.svg";
import revenueIcon from "../../assets/icons/revenue.png";
import { Button } from "antd";
import { Download, GraphUp, GraphUpArrow } from "react-bootstrap-icons";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoPeopleOutline } from "react-icons/io5";
import { Buttons } from "../../components/atoms/index";
import { downloadFile, numberWithCommas } from "../../utils/Helper";

import { RxCross1 } from "react-icons/rx";
import { BsPersonCheck } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa";
import {
  getMainDataDashboard,
  getMainDataDashboardDaily,
  getMainDataDashboardMonthly,
  getMainDataDashboardYearly,
} from "../../services/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  BarChartFill,
  PeopleFill,
  BagFill,
  CheckCircleFill,
} from "react-bootstrap-icons";

export default function MainDashboard() {
  const [mainDataDashboard, setMainDataDashboard] = useState([]);
  const [userName, setUserName] = useState('');
  const [dataChartDaily, setDataChartDaily] = useState([]);
  const [dataChartMonthly, setDataChartMonthly] = useState([]);
  const [dataChartYearly, setDataChartYearly] = useState([]);

  const fetchDashboardData = () => {
    Promise.all([
      getMainDataDashboard(),
      getMainDataDashboardDaily(),
      getMainDataDashboardMonthly(),
      getMainDataDashboardYearly({ start: 2024, end: 2025 }),
    ])
      .then(([dashboardData, dailyData, monthlyData, yearlyData]) => {
        setMainDataDashboard(dashboardData.data.data);
        setDataChartDaily(dailyData.data.data.revenue_data.revenue_per_day);
        setDataChartMonthly(
          monthlyData.data.data.revenue_data.revenue_per_month
        );
        setDataChartYearly(yearlyData.data.data.revenue_data.revenue_per_year);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data: ", error);
      });
  };

  console.log("data", mainDataDashboard)

  useEffect(() => {
    fetchDashboardData();
    const persistAuth = localStorage.getItem('persist:auth-persist');
    if (persistAuth) {
      const authData = JSON.parse(persistAuth);
      const userData = JSON.parse(authData.auth);
      if (userData.user && userData.user.name) {
        setUserName(userData.user.name);
      }
    }
  }, []);

  useEffect(() => {
    Chart.register(ChartDataLabels);
    Chart.register(annotationPlugin);
    const dailyChartElement = document.getElementById("daily-chart");

    if (dailyChartElement) {
      const ctx = dailyChartElement.getContext('2d');

      // Create gradient
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(149, 76, 233, 0.5)');
      gradient.addColorStop(1, 'rgba(149, 76, 233, 0.0)');

      // Days of the week
      const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

      const dailyChart = new Chart(dailyChartElement, {
        type: "line",
        data: {
          labels: daysOfWeek,
          datasets: [
            {
              label: "Daily Revenue",
              data: dataChartDaily.map((data) => data.revenue),
              backgroundColor: gradient,
              borderColor: "rgba(149, 76, 233, 1)",
              borderWidth: 3,
              pointRadius: 0,
              fill: true,
              tension: 0.4
            },
          ],
        },
        options: {
          layout: { padding: 2 },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 14
                }
              }
            },
            y: {
              beginAtZero: true,
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
              },
              display: true,
              max: 1000000,
              ticks: {
                font: {
                  size: 14
                },
                stepSize: 200000,
                callback: (value) => {
                  if (value === 0) return '0';
                  // if (value === 1000000) return '1.000.000';
                  return `Rp. ${numberWithCommas(value)}`;
                },
              }
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: (context) =>
                  `Revenue: ${numberWithCommas(context.parsed.y)}`,
              },
            },
          },
        },
      });

      return () => {
        dailyChart.destroy();
      };
    }
  }, [dataChartDaily]);

  // const getChartOptions = (currency) => {
  //   return {
  //     layout: { padding: 2 },
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     scales: {
  //       x: { display: true },
  //       y: {
  //         beginAtZero: true,
  //         display: true,
  //         ticks: {
  //           stepSize: 200000,
  //           callback: (value) => `${currency} ${numberWithCommas(value)}`,
  //         },
  //       },
  //     },
  //     plugins: {
  //       legend: { display: false },
  //       tooltip: {
  //         callbacks: {
  //           label: (context) =>
  //             `Revenue: ${currency} ${numberWithCommas(context.parsed.y)}`,
  //         },
  //       },
  //     },
  //   };
  // };

  const handleDownloadPDF = () => {
    const input = document.getElementById("dashboard-content");
    setTimeout(() => {
      html2canvas(input, {
        scrollY: -window.scrollY,
        scale: 2,
        logging: false,
        width: input.scrollWidth,
        height: input.scrollHeight + 50,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pageWidth = 210;
        const pageHeight = 295;
        const marginLeft = 10;
        const marginRight = 10;
        const marginTop = 10;
        const marginBottom = 20;
        const imgWidth = pageWidth - marginLeft - marginRight;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(
          imgData,
          "PNG",
          marginLeft,
          marginTop,
          imgWidth,
          imgHeight
        );
        let heightLeft = imgHeight - pageHeight + marginTop;

        while (heightLeft > 0) {
          pdf.addPage();
          pdf.addImage(
            imgData,
            "PNG",
            marginLeft,
            -pageHeight + marginTop + heightLeft,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight - marginTop - marginBottom;
        }

        pdf.save("dashboard-data.pdf");
      });
    }, 2000);
  };

  return (
    <div id="dashboard-content">
      <div className="content-section">
        <div className="mb-6 header flex justify-between items-center">
          <div className="flex flex-col items-center gap-2">
            <p className="text-gray-500">Welcome, <span className="text-gray-900 font-semibold">{userName}!</span></p>
            <h2 className="lg:text-[28px] md:text-[20px] text-[16px]">
              Overview
            </h2>
          </div>
          {/* <Buttons */}
          {/*   type="success" */}
          {/*   icon={<Download />} */}
          {/*   onClick={handleDownloadPDF} */}
          {/*   id="download-button" */}
          {/* > */}
          {/*   Download Data */}
          {/* </Buttons> */}
        </div>
        <div >
          <div className="flex md:flex-row flex-col justify-between gap-x-5 gap-y-3 mb-8">
            <div className="drop-shadow-md bg-white rounded-md w-full flex items-center py-6 px-8">
              <div className="rounded-full bg-[#D9F7BE] p-3 mr-6">
                <img
                  src={transactionIcon}
                  alt="Transaction icon"
                  className="w-8 h-8"
                />
              </div>
              <div>
                <div className="text-baseColor/50 mt-1 text-md font-medium">Total Transaction</div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  Rp.{" "}
                  {mainDataDashboard && mainDataDashboard.total_income
                    ? numberWithCommas(mainDataDashboard.total_income)
                    : "0"}
                </h2>
                {dataChartMonthly.length >= 2 && (
                  <div className="mt-2 flex items-center">
                    <div className="bg-green-100 text-green-800 text-xs font-medium rounded-full flex items-center">
                      <span className="flex flex-row items-center bg-[#ECFDF3] text-darkGreen font-bold px-3 py-0.5 rounded-full">
                        <GraphUpArrow style={{ fontSize: '1rem' }} className="mr-1.5" />
                        {(() => {
                          const currentRevenue = dataChartMonthly[dataChartMonthly.length - 1].revenue;
                          const previousRevenue = dataChartMonthly[dataChartMonthly.length - 2].revenue;
                          const percentChange = previousRevenue !== 0
                            ? ((currentRevenue - previousRevenue) / previousRevenue * 100).toFixed(2)
                            : 'N/A';
                          return percentChange !== 'N/A' ? `+${percentChange}%` : '0%';
                        })()}
                      </span>
                      <span className="ml-1 text-gray-500">from last month</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="drop-shadow-md bg-white rounded-md w-full flex items-center py-6 px-8">
              <div className="rounded-full bg-amber p-3 mr-6">
                <img
                  src={revenueIcon}
                  alt="Revenue icon"
                  className="w-8 h-8 object-contain"
                />
              </div>
              <div>
                <div className="text-baseColor/50 mt-1 text-md font-medium">Total Revenue</div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  Rp.{" "}
                  {mainDataDashboard &&
                    mainDataDashboard.total_income_booking_success
                    ? numberWithCommas(
                      mainDataDashboard.total_income_booking_success
                    )
                    : "0"}
                </h2>
                {dataChartMonthly.length >= 2 && (
                  <div className="mt-2 flex items-center">
                    <div className="bg-green-100 text-green-800 text-xs font-medium rounded-full flex items-center">
                      <span className="flex flex-row items-center bg-[#ECFDF3] text-darkGreen font-bold px-3 py-0.5 rounded-full">
                        <GraphUpArrow style={{ fontSize: '1rem' }} className="mr-1.5" />
                        {(() => {
                          const currentRevenue = dataChartMonthly[dataChartMonthly.length - 1].revenue;
                          const previousRevenue = dataChartMonthly[dataChartMonthly.length - 2].revenue;
                          const percentChange = previousRevenue !== 0
                            ? ((currentRevenue - previousRevenue) / previousRevenue * 100).toFixed(2)
                            : 'N/A';
                          return percentChange !== 'N/A' ? `+${percentChange}%` : '0%';
                        })()}
                      </span>
                      <span className="ml-1 text-gray-500">from last month</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className=" flex md:flex-row flex-col justify-between gap-x-5 gap-y-3">
            <div className="drop-shadow-md bg-white rounded-md w-full flex items-center py-6 px-8">
              <div className="rounded-full bg-[#FFF0F6] p-3 mr-6">
                <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 16 16" id="Users-Group-Two-Rounded--Streamline-Solar" height="16" width="16"><desc>Users Group Two Rounded Streamline Icon: https://streamlinehq.com</desc><g id="Line Duotone/Users/Users Group Two Rounded"><path id="Vector" stroke="#9e1068" d="M7.5 6.5616875C8.881875 6.5616875 10.002125 5.4414375 10.002125 4.0595625S8.881875 1.5574374999999998 7.5 1.5574374999999998C6.118125 1.5574374999999998 4.997875 2.6776875 4.997875 4.0595625S6.118125 6.5616875 7.5 6.5616875Z" stroke-width="1"></path><path id="Vector_2" stroke="#9e1068" stroke-linecap="round" d="M11.2531875 5.9361875C12.289625 5.9361875 13.1298125 5.236 13.1298125 4.3723125000000005S12.289625 2.8085 11.2531875 2.8085" stroke-width="1"></path><path id="Vector_3" stroke="#9e1068" stroke-linecap="round" d="M3.7468125000000003 5.9361875C2.710375 5.9361875 1.8701875000000001 5.236 1.8701875000000001 4.3723125000000005S2.710375 2.8085 3.7468125000000003 2.8085" stroke-width="1"></path><path id="Vector_4" stroke="#9e1068" d="M7.5 13.4425625C9.5728125 13.4425625 11.2531875 12.322312499999999 11.2531875 10.9404375C11.2531875 9.558562499999999 9.5728125 8.4383125 7.5 8.4383125C5.4271875000000005 8.4383125 3.7468125000000003 9.558562499999999 3.7468125000000003 10.9404375C3.7468125000000003 12.322312499999999 5.4271875000000005 13.4425625 7.5 13.4425625Z" stroke-width="1"></path><path id="Vector_5" stroke="#9e1068" stroke-linecap="round" d="M12.504249999999999 12.1915C13.6015625 11.950875 14.380875 11.3414375 14.380875 10.6276875S13.6015625 9.3045 12.504249999999999 9.063812500000001" stroke-width="1"></path><path id="Vector_6" stroke="#9e1068" stroke-linecap="round" d="M2.49575 12.1915C1.3983750000000001 11.950875 0.619125 11.3414375 0.619125 10.6276875S1.3983750000000001 9.3045 2.49575 9.063812500000001" stroke-width="1"></path></g></svg>
              </div>
              <div className="flex items-center justify-between w-full">
                <div>
                  <div className="text-baseColor/50 mt-1 text-md font-medium">Active User</div>
                  <h2 className="font-header tracking-tighter m-0 p-0">
                    {mainDataDashboard &&
                      mainDataDashboard.user_status &&
                      mainDataDashboard.user_status.active}
                  </h2>
                </div>
                <div>
                  <div className="text-baseColor/50 mt-1 text-md font-medium">Unactive User</div>
                  <h2 className="font-header tracking-tighter m-0 p-0">
                    {mainDataDashboard &&
                      mainDataDashboard.user_status &&
                      mainDataDashboard.user_status.not_active}
                  </h2>
                </div>
                <div>
                  <div className="text-baseColor/50 mt-1 text-md font-medium">Total All User</div>
                  <h2 className="font-header tracking-tighter m-0 p-0">
                    {mainDataDashboard &&
                      mainDataDashboard.total_users &&
                      mainDataDashboard.total_users}
                  </h2>
                </div>
              </div>
            </div>
            <div className="drop-shadow-md bg-white rounded-md w-full flex items-center py-6 px-8">
              <div className="rounded-full bg-[#E6F7FF] p-3 mr-4">
                <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Business-Profession-Home-Office--Streamline-Core" height="14" width="14"><desc>Business Profession Home Office Streamline Icon: https://streamlinehq.com</desc><g id="business-profession-home-office--workspace-home-office-work-business-remote-working"><path id="Rectangle 1708" stroke="#0050b3" stroke-linecap="round" stroke-linejoin="round" d="M5.272 6.346h3.455s1 0 1 1v2.456s0 1 -1 1H5.272s-1 0 -1 -1V7.346s0 -1 1 -1" stroke-width="1"></path><path id="Vector 2185" stroke="#0050b3" stroke-linecap="round" stroke-linejoin="round" d="M5.74585 6.34635v-0.57242c0 -0.55229 0.44771 -1 1 -1h0.51c0.55229 0 1 0.44771 1 1v0.57242" stroke-width="1"></path><path id="Vector" stroke="#0050b3" stroke-linecap="round" stroke-linejoin="round" d="M13.5 6.94c0.001 -0.1388 -0.027 -0.27628 -0.0821 -0.40368 -0.0551 -0.1274 -0.1361 -0.24194 -0.2379 -0.33632L7.00002 0.5 0.820023 6.2c-0.101775 0.09438 -0.182787 0.20892 -0.23788 0.33632S0.499084 6.8012 0.500023 6.94v5.56c0 0.2652 0.105357 0.5196 0.292893 0.7071s0.441894 0.2929 0.707104 0.2929H12.5c0.2652 0 0.5196 -0.1054 0.7071 -0.2929 0.1876 -0.1875 0.2929 -0.4419 0.2929 -0.7071V6.94Z" stroke-width="1"></path></g></svg>
              </div>
              <div>
                <div className="text-baseColor/50 mt-1 text-md font-medium">Total Product</div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  {mainDataDashboard && mainDataDashboard.total_office}
                </h2>
              </div>
            </div>
            <div className="drop-shadow-md bg-white rounded-md w-full flex items-center py-6 px-8">
              <div className="rounded-full bg-[#E6FFFB] p-3 mr-4">
                <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 16 16" fill="none" id="Percent--Streamline-Majesticons" height="16" width="16"><desc>Percent Streamline Icon: https://streamlinehq.com</desc><path stroke="#00474f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1.8549999999999998 12.144999999999998L12.144999999999998 1.8549999999999998"></path><path fill="#00474f" stroke="#00474f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.572499999999998 11.2875C9.572499999999998 12.607699999999998 11.001666666666665 13.432824999999998 12.144999999999998 12.772725C12.6756 12.46635833333333 13.002499999999998 11.900233333333333 13.002499999999998 11.2875C13.002499999999998 9.967299999999998 11.573333333333332 9.142174999999998 10.429999999999998 9.802274999999998C9.8994 10.108641666666665 9.572499999999998 10.674766666666667 9.572499999999998 11.2875"></path><path fill="#00474f" stroke="#00474f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M0.9974999999999998 2.7125C0.9974999999999998 4.032699999999999 2.4266666666666663 4.857824999999999 3.5699999999999994 4.197724999999999C4.1006 3.891358333333333 4.427499999999999 3.325233333333333 4.427499999999999 2.7125C4.427499999999999 1.3922999999999999 2.9983333333333326 0.567175 1.8549999999999998 1.2272749999999997C1.3243999999999998 1.5336416666666666 0.9974999999999998 2.0997666666666666 0.9974999999999998 2.7125"></path></svg>
              </div>
              <div>
                <div className="text-baseColor/50 mt-1 text-md font-medium">Current Occupancy</div>
                <p className="text-gray-300">(All office types)</p>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  {mainDataDashboard &&
                    mainDataDashboard.booking_status &&
                    mainDataDashboard.booking_status.success}%
                </h2>
              </div>
            </div>
          </div>
          <h2 className="font-bold mt-6">Transaction</h2>

          {/* <div className="flex justify-between gap-x-5">
            <div className="shadow-custom rounded-md w-full flex items-center py-4 px-8">
              <div className="rounded-full bg-[#FFB997] p-3 mr-4">
                <PeopleFill style={{ fontSize: "1rem", color: "#654062" }} />
              </div>
              <div>
                <div className="text-baseColor">Total User Active</div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  {mainDataDashboard &&
                    mainDataDashboard.user_status &&
                    mainDataDashboard.user_status.active}
                </h2>
              </div>
            </div>
            <div className="shadow-custom rounded-md w-full flex items-center py-4 px-8">
              <div className="rounded-full bg-[#B19CD9] p-3 mr-4">
                <BagFill style={{ fontSize: "1rem", color: "#553D67" }} />
              </div>
              <div>
                <div className="text-baseColor">Total Product</div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  {mainDataDashboard && mainDataDashboard.total_office}
                </h2>
              </div>
            </div>
            <div className="shadow-custom rounded-md w-full flex items-center py-4 px-8">
              <div className="rounded-full bg-[#A0CED9] p-3 mr-4">
                <CheckCircleFill
                  style={{ fontSize: "1rem", color: "#3D5A80" }}
                />
              </div>
              <div>
                <div className="text-baseColor">Total Success Order</div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  {mainDataDashboard &&
                    mainDataDashboard.booking_status &&
                    mainDataDashboard.booking_status.success}
                </h2>
              </div>
            </div>
          </div> */}

          {/* Additional Revenue Information */}
          <div className="flex md:flex-row flex-col justify-between gap-x-5 gap-y-3 mt-8">
            <div className="drop-shadow-md bg-white rounded-md w-full flex items-center py-6 px-8">
              <div className="rounded-full bg-[#D9F7BE] p-3 mr-4">
                <BsPersonCheck
                  style={{ fontSize: "28px", color: "#135200" }} // White icon color for contrast
                />
              </div>
              <div>
                <div className="text-baseColor/50 mt-1 text-md font-medium">
                  Total Client Booking Success
                </div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  {mainDataDashboard &&
                    mainDataDashboard.total_leads_booking_success
                    ? mainDataDashboard.total_leads_booking_success
                    : "0"}{" "}
                  clients
                </h2>
              </div>
            </div>
            <div className="drop-shadow-md bg-white rounded-md w-full flex items-center py-6 px-8">
              {/* <div className="rounded-full bg-[#FFCDD2] p-3 mr-4">
                <CheckCircleFill
                  style={{ fontSize: "1rem", color: "#D32F2F" }}
                />
              </div> */}
              <div className="rounded-full bg-[#FFCDD2] p-3 mr-4">
                <RxCross1
                  style={{ fontSize: "24px", color: "#D92D20" }} />
              </div>
              <div>
                <div className="text-baseColor/50 mt-1 text-md font-medium">
                  Total Client Booking Failed
                </div>
                <h2 className="font-header tracking-tighter m-0 p-0">
                  {mainDataDashboard &&
                    mainDataDashboard.total_leads_booking_expired
                    ? mainDataDashboard.total_leads_booking_expired
                    : "0"}{" "}
                  clients
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 bg-white drop-shadow-md p-6 rounded-xl flex gap-4 flex-col">
          <div className="flex items-center">
            <div className="rounded-full bg-amber p-3 mr-6">
              <img
                src={revenueIcon}
                alt="Revenue icon"
                className="w-8 h-8 object-contain"
              />
            </div>
            <h2 className="font-bold text-xl">Revenue Chart</h2>
          </div>
          <div className="w-full h-64">
            <canvas id="daily-chart" className="w-full h-full"></canvas>
          </div>
        </div>

        {/*TODO: implement latest transaction*/}

        {/* <div className="mt-6 body flex gap-4 flex-col"> */}
        {/*   <div className="flex justify-between"> */}
        {/*     <h2 className="font-bold">Company's Daily Revenue Chart</h2> */}
        {/*   </div> */}
        {/*   <div className="w-full"> */}
        {/*     <canvas id="daily-chart" className="w-full"></canvas> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="mt-4 flex gap-4 md:flex-row flex-col"> */}
        {/*   <div className="body"> */}
        {/*     <div className="flex justify-between"> */}
        {/*       <h2 className="font-bold">Company's Monthly Revenue Chart</h2> */}
        {/*     </div> */}
        {/*     <div className="w-full mt-4"> */}
        {/*       <canvas id="monthly-chart" className="w-full"></canvas> */}
        {/*     </div> */}
        {/*   </div> */}
        {/*   <div className="body basis-3/4"> */}
        {/*     <div className="flex justify-between"> */}
        {/*       <h2 className="font-bold">Company's Yearly Revenue Chart</h2> */}
        {/*     </div> */}
        {/*     <div className="w-full mt-4 h-64"> */}
        {/*       <canvas id="yearly-chart" className="w-full"></canvas> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
