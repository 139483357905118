import React from "react";
import ButtonsPrimary from "./ButtonsPrimary";
import { ArrowLeftShort } from "react-bootstrap-icons";

export default function ButtonBack(props) {
  return (
    <ButtonsPrimary
      className="p-0 h-full mr-3 flex items-center justify-center"
      type={props.type ?? "primary"}
      icon={<ArrowLeftShort style={{ width: 30, height: 30 }} />}
      {...props}
    />
  );
}
