import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Table,
  Upload,
  Select,
  Button,
  message,
  Timeline,
  Typography,
  Alert,
} from "antd";
import {
  Alerts,
  Buttons,
  ButtonBack,
  Images,
  Tags,
  // UploadButton,
} from "../../../../components/atoms";
import { FileEarmarkTextFill } from "react-bootstrap-icons";
import moment from "moment";
import {
  getListCourier,
  processOrders,
  rejectOrders,
  requestDeliveryConfirmation,
  uploadGeneral,
  getTrackingOrders,
  getOrderCreditLimit,
  cancelBooking,
} from "../../../../services/api";
import { formatRupiah, numberWithCommas, translateBookingStatus } from "../../../../utils/Helper";
import validateMessage from "../../../../utils/validateMessage";
import { useEffect } from "react";
import { UploadOutlined, WarningOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export default function OrderDetails(props) {
  const { childData, formatColorStatus, setSection, trigger, setTrigger } = props;
  const [productData, setProductData] = useState([]);
  const [POData, setPOData] = useState([]);
  const [modalApprove, setModalApprove] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const [modalInputReceipt, setModalInputReceipt] = useState(false);
  const [courList, setCourList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [trackingOrder, setTrackingOrder] = useState([]);
  const [creditLimit, setCreditLimit] = useState([]);
  const [form] = Form.useForm();
  const [formReject] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(null);
  const { Option } = Select;
  const [params, setParams] = useState({
    // q: "",
    // distributor_id: childData.distributor_id,
    // order: "co.created_at,desc",
  });
  const paramsCreditLimit = {
    // outlet_id: childData.outlet_id,
    // distributor_id: childData.distributor_id,
  };
  const fetchCourList = () => {
    getListCourier(params)
      .then((res) => {
        setCourList(res.data.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
  const getTrackingOrder = () => {
    getTrackingOrders(childData.id)
      .then((res) => {
        setTrackingOrder(res.data.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const getCreditLimits = () => {
    getOrderCreditLimit(paramsCreditLimit)
      .then((res) => {
        setCreditLimit(res.data.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  function handleAcceptModal() {
    setModalApprove(true);
    getCreditLimits();
    // if () {
    //   setButtonDisabled(true);
    // }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancelClick = () => {
    if (childData.booking_status <= 2) {
      setIsModalOpen(true);
    }
  };

  const handleCancelSubmit = async (id, explanation) => {
    setLoading(true);
    try {
      const data = {
        explanation: explanation,
      };

      const response = await cancelBooking(id, data);

      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "You have successfully canceled this booking",
        });
      } else {
        notification.error({
          message: "Error",
          description: response.data.message,
        });
      }

      setIsModalOpen(false);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
      });
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false);
    }
  };

  function formatColorStatusByText(value) {
    switch (value) {
      case 1:
        return "purple";
      case 2:
        return "orange";
      case 3:
        return "blue";
      case 4:
        return "green";
      case 5:
        return "red";

      default:
        return "green";
    }
  }

  const styles = {
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    modal: {
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      position: "relative",
      width: "90%",
      maxWidth: "500px",
    },
    modalClose: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    buttonPrimary: {
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      padding: "10px 20px",
      cursor: "pointer",
      borderRadius: "4px",
    },
    buttonPrimaryDisabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
    buttonDanger: {
      backgroundColor: "#dc3545",
      color: "white",
      border: "none",
      padding: "10px 20px",
      cursor: "pointer",
      borderRadius: "4px",
    },
    buttonDangerDisabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
      return null;
    }

    return (
      <div style={styles.modalOverlay}>
        <div style={styles.modal}>
          <button style={styles.modalClose} onClick={onClose}>
            &times;
          </button>
          <div style={styles.modalContent}>{children}</div>
        </div>
      </div>
    );
  };

  const changeQtyPO = (value, id) => {
    const result = [...productData];
    const index = result.findIndex((object) => {
      return object.product_order_id === id;
    });
    result[index].qty_po = parseInt(value);
    result[index].canceled = result[index].qty_sp - parseInt(value);
    setProductData(result);
  };

  let grandTotal = 0;

  function calculateTotalPrice(order) {
    let totalPrice = 0;
    let lastValidQuantity = 0;

    order?.product_wholesale_price?.forEach((item) => {
      if (order.qty_po >= item.quantity) {
        lastValidQuantity = item.quantity;
      }
    });

    if (lastValidQuantity > 0) {
      let lastValidPrice = order.product_wholesale_price.find(
        (item) => item.quantity === lastValidQuantity,
      ).price;
      totalPrice = lastValidPrice * order.qty_po;
    } else {
      totalPrice = order.product_price * order.qty_po;
    }
    if (lastValidQuantity !== 0) {
      console.log(
        `Quantity item telah mencakup minimal ${lastValidQuantity} maka akan dikenakan harga grosir`,
      );
    } else {
      console.log(
        "Quantity tidak mencakup minimal harga grosir / tidak ada harga grosir di produk tersebut",
      );
    }
    return totalPrice;
  }

  productData.forEach((order) => {
    let totalPrice = calculateTotalPrice(order);
    grandTotal += totalPrice;
    console.log(`Total harga untuk pesanan ${order.product_name}: ${totalPrice}`);
    console.log(`Total harga untuk order ${grandTotal}`);
  });

  const acceptOrder = () => {
    let product_data = [];
    let total_po = 0;
    productData?.forEach((item) => {
      const data = {
        product_order_id: item.product_order_id,
        qty_send: item.qty_po || 0,
      };
      total_po = total_po + (item.qty_po || 0);
      product_data.push(data);
    });
    const result = {
      order_number: childData.order_number,
      order_id: childData.id,
      product_data,
    };
    if (total_po > 0) {
      processOrders(result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Sukses!",
              description: "Sukses Proses Order",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Gagal!",
            description: "Gagal Proses Order",
            placement: "topRight",
          });
          throw new Error(err);
        });
    } else {
      notification.error({
        message: "Gagal!",
        description: "QTY PO harus diisi!",
        placement: "topRight",
      });
    }
  };

  const rejectOrder = (values) => {
    const result = {
      order_number: childData.order_number,
      reason: values.reason,
    };
    rejectOrders(result)
      .then((res) => {
        if (res) {
          notification.success({
            message: "Sukses!",
            description: "Sukses Tolak Order",
            placement: "topRight",
          });
          setSection("default");
          setTrigger(!trigger);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Gagal!",
          description: "Gagal Tolak Order",
          placement: "topRight",
        });
        throw new Error(err);
      });
  };
  const handleReceiptInput = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          ...values,
          courier_id: values.courier_id.toString(),
          order_id: childData.id,
          tax_invoice_note_image: fileList.url,
          invoice_image: fileList2.url,
          road_letter_notes_image: fileList3.url,
          delivery_proof: "",
        };
        requestDeliveryConfirmation(payload)
          .then((res) => {
            if (res) {
              notification.success({
                message: "Sukses!",
                description: "Sukses Update Resi",
                placement: "topRight",
              });
              setSection("default");
              setTrigger(!trigger);
            }
          })
          .catch((err) => {
            notification.error({
              message: "Gagal!",
              description: "Gagal Update Resi",
              placement: "topRight",
            });
            throw new Error(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const beforeUploadTaxInvoice = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload PDF/JPG/JPEG/PNG file!");
    }
    if (isJpgOrPng) {
      const formData = new FormData();
      formData.append("file", file);
      await uploadGeneral(formData)
        .then((res) => {
          const result = res.data.data;
          const imageResult = {
            name: result.file_path,
            url: result.file_url,
          };
          setFileList(imageResult);
        })
        .catch((err) => {
          throw new Error(err);
        });
      return false;
    }
    return isJpgOrPng || Upload.LIST_IGNORE;
  };
  const beforeUploadInvoice = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload PDF/JPG/JPEG/PNG file!");
    }
    if (isJpgOrPng) {
      const formData = new FormData();
      formData.append("file", file);
      await uploadGeneral(formData)
        .then((res) => {
          const result = res.data.data;
          const imageResult = {
            name: result.file_path,
            url: result.file_url,
          };
          setFileList2(imageResult);
        })
        .catch((err) => {
          throw new Error(err);
        });
      return false;
    }
    return isJpgOrPng || Upload.LIST_IGNORE;
  };
  const beforeUploadRoadLetter = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload PDF/JPG/JPEG/PNG file!");
    }
    if (isJpgOrPng) {
      const formData = new FormData();
      formData.append("file", file);
      await uploadGeneral(formData)
        .then((res) => {
          const result = res.data.data;
          const imageResult = {
            name: result.file_path,
            url: result.file_url,
          };
          setFileList3(imageResult);
        })
        .catch((err) => {
          throw new Error(err);
        });
      return false;
    }
    return isJpgOrPng || Upload.LIST_IGNORE;
  };

  const normFileDocument = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const onChangeCourier = (val) => {
    const index = courList.map((e) => e.id).indexOf(val);
    form.setFieldsValue({
      phone_number: courList[index].phone_number,
    });
  };

  function trackModal() {
    Modal.info({
      content: (
        <>
          <div className="font-bold text-lg mt-3">Data Kurir</div>
          <div className="flex justify-between mt-3">
            <div>
              <div className="font-bold">Nama</div>
              <div className="font-bold">No Handphone</div>
            </div>
            <div className="mb-2">
              <div className="text-right">{trackingOrder?.courier_info?.name}</div>
              <div className="text-right">{trackingOrder?.courier_info?.phone_number}</div>
            </div>
          </div>
          <div className="border-b border-gray-300"></div>
          <div className="mt-5">
            <Timeline>
              {trackingOrder?.tracking?.map((data) => (
                <Timeline.Item color="green">
                  <div>{data.status}</div>
                  <p className="text-secondary">{data.created_at}</p>
                </Timeline.Item>
              ))}
            </Timeline>
          </div>
        </>
      ),
      onOk() {},
    });
  }

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">Order Details</div>
          </div>
        </div>
        <div className="body-with-footer">
          <div className="body-content">
            {/* Booking Details Section */}
            <div className="booking-details">
              <div className="flex items-center justify-between">
                <h2 className="text-primary font-bold">Booking Detail</h2>
                <Tags
                  color={formatColorStatus(childData.booking_status)}
                  title={translateBookingStatus(childData.booking_status)}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <div className="text-secondary">Booking Number</div>
                  <div>{childData.booking_invoice || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Booking Status</div>
                  <div>{translateBookingStatus(childData.booking_status) || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Customer Name</div>
                  <div>{childData.customer_name || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Office Name</div>
                  <div>{childData.office_name || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Booking Date</div>
                  <div>
                    {moment(childData.booking_start_date)
                      .zone("+00:00")
                      .format("DD/MM/YYYY hh:mm A")}{" "}
                    -{" "}
                    {moment(childData.booking_end_date)
                      // .utcOffset("+07:00")
                      // .utcOffset(420)
                      .zone("+00:00")
                      .format("DD/MM/YYYY hh:mm A")}
                  </div>
                </div>
                <div>
                  <div className="text-secondary">Hour Count</div>
                  <div>{childData.hour_count || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Days Count</div>
                  <div>{childData.days_count || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Week Count</div>
                  <div>{childData.week_count || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Month Count</div>
                  <div>{childData.month_count || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Payment Type</div>
                  <div>
                    {childData.payment_type === "payment_gateway"
                      ? "Payment Gateway"
                      : "Unknown Payment Type"}
                  </div>
                </div>
                {/* <div> */}
                {/*   <div className="text-secondary">Discount</div> */}
                {/*   <div>{childData.discount || "-"}</div> */}
                {/* </div> */}
                <div>
                  <div className="text-secondary">Payment URL</div>
                  <div>
                    {childData.va_account ? (
                      <a href={childData.va_account} target="_blank" rel="noopener noreferrer">
                        Click Here
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-secondary">Booking Price</div>
                  <div>Rp. {formatRupiah(childData.booking_price)}</div>
                </div>
                <div>
                  <div className="text-secondary">Overtime Count</div>
                  <div>{childData.overtime_count} hour</div>
                </div>
                <div>
                  <div className="text-secondary">Overtime Price</div>
                  <div>Rp. {formatRupiah(childData.overtime_price)}</div>
                </div>
                <div>
                  <div className="text-secondary">Catering Price</div>
                  <div>Rp. {formatRupiah(childData.catering_price)}</div>
                </div>
                <div>
                  <div className="text-secondary">Total Price</div>
                  <div>Rp. {formatRupiah(childData.total_price)}</div>
                </div>
                <div>
                  <div className="text-secondary">PPN 11%</div>
                  <div>Rp. {formatRupiah(childData.ppn)}</div>
                </div>
                <div>
                  <div className="text-secondary">Deposit</div>
                  <div>Rp. {formatRupiah(childData.deposit)}</div>
                </div>
                <div>
                  <div className="text-secondary">Final Price</div>
                  <div>Rp. {formatRupiah(childData.final_price)}</div>
                </div>

                <div>
                  <div className="text-secondary">User Description</div>
                  <div>{childData.uses_description || "-"}</div>
                </div>
                <div>
                  <div className="text-secondary">Notes</div>
                  <div>{childData.notes || "-"}</div>
                </div>
              </div>
            </div>

            {/* Catering Details Section */}
            {childData.catering_details && childData.catering_details.length > 0 && (
              <div className="catering-details mt-6">
                <h2 className="text-primary font-bold">Catering Details</h2>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  {childData.catering_details.map((catering, index) => (
                    <div key={index}>
                      <div className="text-secondary">Catering Name</div>
                      <div>{catering.catering_name || "-"}</div>
                      <div className="text-secondary">Unit Price</div>
                      <div>Rp. {formatRupiah(catering.catering_unit_price)}</div>
                      <div className="text-secondary">Count Pax</div>
                      <div>{catering.catering_count_pax}</div>
                      <div className="text-secondary">Total Price</div>
                      <div>Rp. {formatRupiah(catering.catering_price)}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Payment Details Section */}
            {childData.payment && (
              <div className="payment-details mt-6">
                <h2 className="text-primary font-bold">Payment Details</h2>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <div>
                    <div className="text-secondary">Payment Invoice</div>
                    <div>{childData.payment.payment_invoice || "-"}</div>
                  </div>
                  <div>
                    <div className="text-secondary">Payment Status</div>
                    <div>{translateBookingStatus(childData.payment.payment_status) || "-"}</div>
                  </div>
                  <div>
                    <div className="text-secondary">Total Price</div>
                    <div>Rp. {formatRupiah(childData.payment.total_price)}</div>
                  </div>
                  <div>
                    <div className="text-secondary">Expired At</div>
                    <div>{moment(childData.payment.expired_at).format("DD/MM/YYYY hh:ss")}</div>
                  </div>
                  <div>
                    <div className="text-secondary">Created At</div>
                    <div>{moment(childData.payment.created_at).format("DD/MM/YYYY hh:ss")}</div>
                  </div>
                  <div>
                    <div className="text-secondary">Updated At</div>
                    <div>{moment(childData.payment.updated_at).format("DD/MM/YYYY hh:ss")}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className="footer">
            <button
              className="btn btn-danger"
              onClick={handleCancelClick}
              disabled={childData.booking_status > 2}
            >
              Cancel Booking
            </button>
          </div> */}
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <div className="modal-content">
            <h2>Cancel Booking</h2>
            <textarea
              placeholder="Reason for cancellation"
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            ></textarea>
            <button className="btn btn-primary" onClick={handleCancelSubmit} disabled={loading}>
              Submit
            </button>
            {loading && <div>Loading...</div>}
          </div>
        </Modal>
      )}
    </>
  );
}
