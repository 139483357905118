import React, { useState, useEffect } from "react";
import { Images, Tags } from "../../../components/atoms/index";
import { formatRupiah } from "../../../utils/Helper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default function OfficeDetail(props) {
  // icon marker
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const {
    childData,
    AddOnsInformationRef,
    AddOnsImageRef,
    AddOnsPriceRef,
  } = props;
  const [listImage, setListImage] = useState([]);

  useEffect(() => {
    getListImage();
  }, []);

  function getListImage() {
    const images = [childData.url];
    setListImage(images);
  }

  return (
    <div className="pb-8 pt-2">
      <div>
        <h2 ref={AddOnsInformationRef} className="text-lg font-semibold text-primary mb-2">
          Add Ons Data
        </h2>
        <div className="mb-4">
          <div className="text-secondary mb-2">Office Pictures</div>
          <div className="flex gap-4 mb-4">
            {listImage?.map((item, index) => (
              <Images src={item} key={index} width={70} height={70} />
            ))}
            {listImage?.length < 1 && <div>No pictures</div>}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary">Add On Name</div>
            <div>{childData.name}</div>
          </div>
          <div>
            <div className="text-secondary">Category</div>
            <div>{childData.category_name}</div>
          </div>
          <div>
            <div className="text-secondary">Status</div>
            <Tags
              title={childData.status ? "Active" : "Not Active"}
              color={childData.status ? "green" : "red"}
            />
          </div>
        </div>
        <h2
          ref={AddOnsImageRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Description
        </h2>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div>{childData.description || "-"}</div>
          </div>
        </div>
        <h2
          ref={AddOnsPriceRef}
          className="mt-6 text-lg font-semibold text-primary mb-2"
        >
          Price
        </h2>
        <div className="grid grid-cols-2 gap-4">
          {childData.category === 11 ? (
            <div>
              Rp. {formatRupiah(childData.catering_price.price_per_event)}{" "}
              {childData.catering_price.pax_type}
            </div>
          ) : (
            <div>
              Rp. {formatRupiah(childData.catering_price.price_per_pax)}{" "}
              {childData.catering_price.pax_type}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
