import React, { useEffect, useState } from "react";
import { Input, Switch, Col, Row, Form, notification } from "antd";
import { Buttons, ButtonBack, Tags } from "../../../components/atoms/index";
import validateMessage from "../../../utils/validateMessage";
import { putFAQ, postFAQ } from "../../../services/api";
import TextEditor from "../../../components/molecules/TextEditor";

const { TextArea } = Input;

export default function FormSection(props) {
  const { setSection, section, setTrigger, trigger, childData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [questionLength, setQuestionLength] = useState([1]);
  const [questionIDs, setQuestionIDs] = useState({});

  const onFinish = (values) => {
    // const questions = [];
    // if (section === "add") {
    //   questionLength.forEach((item) => {
    //     const question_temp = `question_${item}`;
    //     const answer_temp = `answer_${item}`;
    //     const data = {
    //       question: values[question_temp],
    //       answer: values[answer_temp],
    //     };
    //     questions.push(data);
    //   });
    // }
    // if (section === "edit") {
    //   const questionIdLength = Object.keys(questionIDs)?.length / 2;
    //   questionLength.forEach((item) => {
    //     const question_temp = `question_${item}`;
    //     const answer_temp = `answer_${item}`;
    //     let data = {};
    //     if (item <= questionIdLength) {
    //       data = {
    //         id: questionIDs[`id_${item}`],
    //         faq_id: questionIDs[`faq_id_${item}`],
    //         question: values[question_temp],
    //         answer: values[answer_temp],
    //       };
    //     } else {
    //       data = {
    //         question: values[question_temp],
    //         answer: values[answer_temp],
    //       };
    //     }
    //     questions.push(data);
    //   });
    // }
    // const result = {
    //   topic: {
    //     title: values.title,
    //     is_active: values.is_active,
    //   },
    //   questions,
    // };

    const result = {
      ...values,
      question: values?.question,
      answer: content ? content : childData.answer,
    };

    if (section === "add") {
      setLoading(true);
      postFAQ(result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Add New FAQ",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Add New FAQ",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (section === "edit") {
      setLoading(true);
      putFAQ(childData.id, result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Edit FAQs",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Edit FAQ",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (section === "edit") {
      // setQuestionLength(
      //   Array.from({ length: childData.questions.length }, (_, i) => i + 1)
      // );
      // let updatedIds = {};
      // let questionData = {};
      // childData.questions.forEach((item, index) => {
      //   questionData[`question_${index + 1}`] = item.question;
      //   questionData[`answer_${index + 1}`] = item.answer;
      //   updatedIds[`id_${index + 1}`] = item.id;
      //   updatedIds[`faq_id_${index + 1}`] = item.faq_id;
      // });
      // setQuestionIDs(updatedIds);
      form.setFieldsValue({
        question: childData.question,
        answer: childData.answer,
        // ...questionData,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (e) => {
    setContent(e);
  };

  // function addQuestion() {
  //   setQuestionLength((prevState) => [...prevState, questionLength.length + 1]);
  // }

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add FAQ"}
              {section === "view" && "Detail"}
              {section === "edit" && "Edit FAQ"}
            </div>
          </div>
        </div>
        {section !== "view" ? (
          <div className="body-with-footer">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              validateMessages={validateMessage}
            >
              <div className="body-content">
                <Row gutter={16}>
                  <Col span={12}>
                    {/* <Form.Item
                      label="Status"
                      name="is_active"
                      valuePropName="checked"
                      initialValue={false}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Switch />
                    </Form.Item> */}

                    <Form.Item
                      label="Question"
                      name="question"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Type a question" />
                    </Form.Item>
                  </Col>
                  <div className="px-3 flex flex-col gap-2 w-full">
                    <label>Answer</label>

                    <TextEditor
                      fieldValue={childData.answer}
                      onFieldChange={handleFieldChange}
                      disabled={section === "view"}
                    />
                  </div>
                </Row>

                {/* {questionLength?.map((item) => (
                  <div key={item}>
                    <div className="text-primary font-medium text-md mb-3 mt-2">
                      Question {item}
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                      <Form.Item
                        label="Pertanyaan"
                        name={`question_${item}`}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          autoSize={{ minRows: 4 }}
                          placeholder="Type a question"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Answer"
                        name={`answer_${item}`}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          autoSize={{ minRows: 4 }}
                          placeholder="Type an answer"
                        />
                      </Form.Item>
                    </div>
                  </div>
                ))} */}
                {/* <div
                  className="flex items-center text-primary font-medium cursor-pointer hover:underline mt-5"
                  onClick={addQuestion}
                >
                  <PlusOutlined className="mr-1 stroke-primary stroke-[50]" />
                  <span className="text-md">Add Question</span>
                </div> */}
              </div>
              <div className="footer">
                <Buttons onClick={() => setSection("default")}>Cancel</Buttons>
                <Form.Item type="button">
                  <Buttons type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Buttons>
                </Form.Item>
              </div>
            </Form>
          </div>
        ) : (
          <div className="body pb-16">
            {/* <div className="grid grid-cols-2 gap-4"> */}
            {/* <div>
                <div className="text-gray-500 mb-2">Number</div>
                <div className="mb-4">{childData?.number || "-"}</div>
              </div> */}

            {/* <div>
                <div className="text-gray-500 mb-2">Status</div>
                <Tags
                  color={childData?.topic?.is_active ? "green" : "red"}
                  title={childData?.topic?.is_active ? "Aktif" : "Tidak Aktif"}
                />
              </div> */}
            {/* </div> */}

            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-gray-500 mb-2">Question</div>
                <div className="mb-4">{childData?.question}</div>
              </div>
              <div>
                <div className="text-gray-500 mb-2">Answer</div>
                <div className="mb-4">
                  {childData?.answer?.replace(/(<([^>]+)>)/gi, "")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
