import React, { useState, useEffect } from "react";
import { Table, Modal, notification, Image } from "antd";
import _ from "lodash";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FormSection from "./FormSection";
import { getBannerList, deleteBanner } from "../../../services/api";
import {
  Buttons,
  CustomPagination,
  InputSearch,
} from "../../../components/atoms/index";

const { confirm } = Modal;

export default function ListBanner() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "id,desc",
    keyword: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, params]);

  const getData = () => {
    setLoading(true);
    getBannerList(params)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total_data);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setParams({ ...params, keyword: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section]);

  const columns = [
    {
      title: "Banner Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <div className="flex items-center gap-3">
          <Image
            src={record.url}
            height={50}
            width={100}
            rootclassname="object-contain"
          />
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Order",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div className="flex items-center gap-x-1">
          <Buttons
            className="py-1 rounded-lg"
            icon={<EyeFilled />}
            onClick={() => viewData(record)}
          />
          <Buttons
            className="py-1 rounded-lg"
            icon={<EditFilled />}
            onClick={() => editData(record)}
          />
          <Buttons
            className="py-1 rounded-lg"
            icon={<DeleteFilled />}
            onClick={() => deleteData(record.id)}
          />
        </div>
      ),
    },
  ];

  const editData = (record) => {
    setChildData(record);
    setSection("edit");
  };

  const viewData = (record) => {
    setChildData(record);
    setSection("view");
  };

  const addData = () => {
    // if (data.length >= 5) {
    //   notification.error({
    //     message: "Failed!",
    //     description: "Maximum can only add 5 banners",
    //     placement: "topRight",
    //   });
    // } else
    setSection("add");
  };

  const deleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteBanner(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2>Banner List</h2>
            <Buttons
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addData()}
            >
              Add Data
            </Buttons>
          </div>
          <div className="body">
            <div className="mb-2 w-80">
              <InputSearch onChange={onSearch} />
            </div>
            <Table
              dataSource={data}
              columns={columns}
              rowKey="id"
              loading={loading}
            />
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
