import { Input } from "antd";
import React from "react";
import { BiSearch } from "react-icons/bi";

export default function InputSearch(props) {
  return (
    <Input
      placeholder={props.placeholder ?? "Search"}
      prefix={<BiSearch className="text-gray-400 ml-2" />}
      allowClear
      {...props}
    />
  );
}
