export default function formatRole(role) {
  switch (role) {
    case "SUPERADMIN":
      return "blue";
    case "Admin":
      return "purple";
    case "Finance":
      return "orange";
    case "Pengadaan":
      return "red";
    default:
      return "default";
  }
}
