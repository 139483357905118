import { Col, DatePicker, Form, notification, Row, Select } from "antd";
import { ButtonBack, Buttons } from "../../../components/atoms";
import validateMessage, { getLabel } from "../../../utils/validateMessage";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import {
  createBooking,
  getListOffice,
  getPaymentList,
} from "../../../services/api";

export default function FormBooking(props) {
  const { section, setSection } = props;
  const [form] = Form.useForm();
  const [optionOffice, setOptionOffice] = useState([]);
  const [optionPayment, setOptionPayment] = useState([]);
  const [paramOffice] = useState({
    offset: 0,
    keyword: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getListOffice(paramOffice).then((res) => {
      if (Array.isArray(res.data.data.data)) {
        setOptionOffice(res.data.data.data);
      } else {
        setOptionOffice([res.data.data.data]);
      }
    });
  }, [paramOffice]);

  useEffect(() => {
    getPaymentList().then((res) => {
      setOptionPayment(res.data.data);
    });
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);
    createBooking(event)
      .then((res) => {
        notification.success({
          message: "Success!",
          description: res.data.message ?? "Successfully added booking",
          placement: "topRight",
        });
        setSection("default");
      })
      .catch((err) => {
        notification.error({
          message: "Failed!",
          description: "Failed to add booking!",
          placement: "topRight",
        });
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add Booking"}
              {section === "edit" && "Edit Booking"}
            </div>
          </div>
        </div>
        <div className="body max-body-height pt-3 pb-0">
          <Form
            form={form}
            onFinish={handleSubmit}
            validateMessages={validateMessage}
            layout="vertical"
          >
            <div className="relative scroll-custom">
              <div className="fit-scroll py-2">
                <div className="text-lg font-semibold text-primary mb-2">
                  Booking Data
                </div>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Office Name", true)}
                      name="office_id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a office"
                        fieldNames={{
                          label: "name",
                          value: "id",
                        }}
                        options={optionOffice}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Payment Type", true)}
                      name="payment_type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a payment"
                        fieldNames={{
                          label: "name",
                          value: "id",
                        }}
                        options={optionPayment}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Start Date"
                      name="booking_start_date"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        showTime
                        format="DD MMM YYYY HH:mm:ss"
                        className="w-full"
                        disabled={section === "view"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="End Date"
                      name="booking_end_date"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        showTime
                        format="DD MMM YYYY HH:mm:ss"
                        className="w-full"
                        disabled={section === "view"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Description"
                      name="uses_description"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea rows={5} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="border-t-2 -mx-5">
              <div className="flex justify-end gap-3 px-5 pt-4">
                <Buttons onClick={() => setSection("default")}>Cancel</Buttons>
                <Form.Item type="button">
                  <Buttons type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Buttons>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
