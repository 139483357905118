import { SET_USER_DATA } from "./types";

export const getUserData = (data) => (dispatch) => {
  dispatch({
    type: SET_USER_DATA,
    payload: { value: data },
  });
};

export const resetUserData = () => (dispatch) => {
  // in order to reset user data, we need to remove the token
  // from the local storage, this has not been implemented before,
  // making people who already logged out, can still access the dashboard
  // if they changed the routing
  localStorage.removeItem('token');
  dispatch({
    type: SET_USER_DATA,
    payload: {
      value: {
        token: null,
      },
    },
  });
};
