import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
// import HTMLReactParser from "html-react-parser";

export default function TextEditor(props) {
  const { disabled = false, fieldValue = "", onFieldChange } = props;

  return (
    <>
      <SunEditor
        setOptions={{
          minHeight: "40vh",
          placeholder: "Tulis Artikel Disini!",
          plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link,
          ],
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image"],
            ["codeView", "preview"],
          ],
          formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
        }}
        defaultValue={fieldValue}
        onChange={onFieldChange}
        disable={disabled}
      />
      {/* <div>{HTMLReactParser(value)}</div> */}
    </>
  );
}
