import React, { useState, useEffect } from "react";
import { Table, Modal, notification } from "antd";
import _ from "lodash";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FormSection from "./FormSection";
import { DeleteData, getAllAdmin } from "../../../services/api";
import { BiDownload } from "react-icons/bi";
import {
  Buttons,
  CustomPagination,
  InputSearch,
  Tags,
} from "../../../components/atoms/index";
import { downloadFile } from "../../../utils/Helper";
import formatRole from "../../../utils/formatRole";
import { saveAs } from "file-saver";
import moment from "moment";

const { confirm } = Modal;

export default function ListAdmin() {
  const [data, setData] = useState([]);
  const [totalData, setTotal] = useState(0);
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [childData, setChildData] = useState({});
  const [reload, setReload] = useState(0);
  // const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "admin_users.id,desc",
    keyword: "",
  });
  const [loadingDownload, setLoadingDownload] = useState(false);

  useEffect(() => {
    getData();
  }, [trigger, params, reload]);

  const getData = () => {
    setLoading(true);
    getAllAdmin(params)
      .then(({ data: { data: res } }) => {
        setData(res?.data);
        setTotal(res?.data.length);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setParams({ ...params, keyword: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section]);

  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: (a, b) => a.phone_number - b.phone_number,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => {
        return <Tags color={formatRole(role)} title={`${role}`} />;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={
                <DeleteFilled className="text-gray-600 hover:text-primary" />
              }
              onClick={() => confirmDeleteData(record.id)}
            />
          </div>
        </>
      ),
    },
  ];

  const editData = (record) => {
    setChildData(record);
    setSection("edit");
  };

  const viewData = (record) => {
    console.log(record);
    setChildData(record);
    setSection("view");
  };

  const addData = () => {
    setSection("add");
  };

  const confirmDeleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          DeleteData(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              setReload(reload + 1);
              // getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  // const [loadingDownload, setLoadingDownload] = useState(false);

  // const handleDownloadData = () => {
  //   setLoadingDownload(true);
  //   downloadListAdminUsers(params)
  //     .then((res) => {
  //       downloadFile(res.data.data);
  //     })
  //     .catch((err) => {
  //       return new Error(err);
  //     })
  //     .finally(() => {
  //       setLoadingDownload(false);
  //     });
  // };

  const downloadFile = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/report?user_type=admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const blob = await response.blob();
      saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2 className=" lg:text-[28px] text-[20px]">List Admin</h2>
            <Buttons
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addData()}
            >
              Add Data
            </Buttons>
          </div>
          <div className="body">
            <div className="flex justify-between">
              <div className="mb-2 md:w-80 w-32">
                <InputSearch onChange={onSearch} />
              </div>
              <Buttons
                type="success"
                icon={<BiDownload />}
                loading={loadingDownload}
                onClick={downloadFile}
              >
                Download Data
              </Buttons>
            </div>
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                // dataSource={dummyData}
                columns={columns}
                rowKey="id"
                // loading={loading}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
