import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Modal } from "antd";
import Sidebar from "../../components/organism/Sidebar";
import { resetUserData } from "../../redux/auth/action";
import { useDispatch } from "react-redux";
import TopNavbar from "../../components/organism/Topbar";
const { Content } = Layout;

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  useEffect(() => {
    if (location?.state?.prevPath === "/admin/set-password") {
      Modal.error({
        title: "Your session has ended,",
        content: "You will be redirected to the Login Page!",
        onOk: () => {
          dispatch(resetUserData());
          Modal.destroyAll();
        },
      });
    }
  }, []);

  return (
    <>
      <Layout className="h-screen" style={{ minHeight: "100vh" }}>
        <TopNavbar style={{ position: "fixed", zIndex: 1, width: "100%" }} />
        <Layout>
          <div className=" bg-white hidden md:block">
            <Sidebar
              style={{ position: "fixed", height: "100%" }}
              currentPath={currentPath}
            />
          </div>

          <Layout>
            <Content
              className="bg-[#FAFAFA]"
              style={{
                padding: 24,
                margin: 0,
                overflow: "auto",
                height: "100vh",
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default DashboardLayout;
