import { PlusOutlined } from "@ant-design/icons";
import React from "react";

export default function UploadButton() {
  return (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
}
