import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, notification } from "antd";
import _ from "lodash";
import {
  Buttons,
  CustomPagination,
  InputSearch,
} from "../../../components/atoms/index";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FormSection from "./FormSection/FormSection";
import {
  getListOffice,
  getListOfficeDetail,
  deleteOffice,
  importProductData,
  downloadListProduct,
  downloadTemplateImport,
} from "../../../services/api";
import { BiDownload } from "react-icons/bi";
import {
  downloadFile,
  formatRupiah,
  numberWithCommas,
} from "../../../utils/Helper";
import { useSelector } from "react-redux";

const { confirm } = Modal;

export default function CoworkingSpaceND() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const distributor_id = useSelector((state) => state.auth.user.distributor_id);
  const roles = useSelector((state) => state.auth.user.role_slug);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    keyword: "",
  });

  const getData = () => {
    setLoading(true);
    getListOffice(params)
      .then((res) => {
        let officeData = Array.isArray(res.data.data.data)
          ? res.data.data.data
          : [res.data.data.data];
        const filteredData = officeData.filter(
          (item) => item.office_category === 6
        );
        setData(filteredData);
        setTotal(filteredData.length);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [trigger, params]);

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setData([]);
    setParams({ ...params, keyword: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
      getData();
    }
  }, [section]);

  const columns = [
    // {
    //   title: "Office Number",
    // },
    {
      title: "Office Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Region",
      dataIndex: "region_name",
      sorter: (a, b) => a.region_name - b.region_name,
    },
    {
      title: "Location",
      render: (text, record) => {
        const location =
          record.office_details && record.office_details.location;
        if (location.length > 70) {
          return `${location.substring(0, 70)} ...`;
        } else {
          return location;
        }
      },
    },
    {
      title: "Price Per Day",
      render: (text, record) => {
        return <span>Rp {numberWithCommas(record.price?.price_daily)}</span>;
      },
      sorter: (a, b) => a.price.price_daily - b.price.price_daily,
    },
    // {
    //   title: "Discount",
    //   dataIndex: "discount",
    //   render: (number) => (number ? `${number}%` : "-"),
    // },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={
                <DeleteFilled className="text-gray-600 hover:text-primary" />
              }
              onClick={() => deleteData(record)}
            />
          </div>
        </>
      ),
    },
  ];

  const editData = (record) => {
    getListOfficeDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("edit");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const viewData = (record) => {
    getListOfficeDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("view");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const addData = () => {
    setSection("add");
  };

  const deleteData = (record) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteOffice(record.id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: err?.data?.stat_msg
                  ? err?.data?.stat_msg
                  : "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    setLoadingImport(true);
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append("file", fileUploaded);
    await importProductData(formData)
      .then((res) => {
        if (res) {
          notification.success({
            message: "Success!",
            description: "Successfully import office data",
            placement: "topRight",
          });
          getData();
        }
      })
      .catch((err) => {
        notification.error({
          message: "Failed!",
          description: "Failed to import office data",
          placement: "topRight",
        });
        throw new Error(err);
      })
      .finally(() => {
        setLoadingImport(false);
      });
  };

  const handleDownloadData = () => {
    setLoadingDownload(true);
    downloadListProduct(params)
      .then((res) => {
        downloadFile(res.data.data);
      })
      .catch((err) => {
        return new Error(err);
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };
  const handleDownloadTemplate = () => {
    downloadTemplateImport()
      .then((res) => {
        downloadFile(res.data.data);
      })
      .catch((err) => {
        return new Error(err);
      });
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px]">
              Coworking Space (Non-Dedicated)
            </h2>
            <div className="flex flex-col md:flex-row  items-center md:gap-4 gap-1">
              <Buttons
                type="success"
                icon={<BiDownload />}
                loading={loadingDownload}
                onClick={handleDownloadData}
              >
                Download Data
              </Buttons>
              {roles !== "super-admin-distributor" && (
                <Buttons
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => addData()}
                >
                  Add Data
                </Buttons>
              )}
            </div>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="body">
            <div className="mb-2 w-80">
              <InputSearch onChange={onSearch} />
            </div>
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
