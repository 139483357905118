import { CheckCircleFilled } from "@ant-design/icons";
import React from "react";
import { InfoCircleFill, ExclamationCircleFill } from "react-bootstrap-icons";
export default function Alerts(props) {
  const { type = "info", title, message, boldMessage } = props;
  const typeClass = {
    info: "bg-primary-50 text-navy",
    danger: "bg-danger-50 text-danger",
    success: "bg-success-50 text-[#166534]",
  };

  const titleClass = {
    info: "font-semibold",
    danger: "font-semibold text-dark-red",
    success: "font-semibold text-[#166534]",
  };

  return (
    <>
      <div className={"py-3 px-6 rounded-md " + `${typeClass[type]}`}>
        <div className="flex items-start gap-2">
          {type === "info" && <InfoCircleFill className="text-primary" />}
          {type === "success" && (
            <CheckCircleFilled className="text-success text-md" />
          )}
          {type === "danger" && (
            <ExclamationCircleFill className="text-danger mt-[2px]" />
          )}
          <div>
            <div className={titleClass[type]}>{title}</div>
            <div>
              {message}
              <strong> {boldMessage}</strong>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
