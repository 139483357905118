import React, { useState, useEffect } from "react";
import { Table, Modal, notification } from "antd";
import _ from "lodash";
import {
  Buttons,
  CustomPagination,
  InputSearch,
  Tags,
} from "../../../components/atoms/index";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FormSection from "./FormSection";
import { deleteFAQ, getFAQ, getFAQDetail } from "../../../services/api";

const { confirm } = Modal;

export default function ListArtikel() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "id,desc",
    keyword: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, params]);

  const getData = () => {
    setLoading(true);
    getFAQ(params)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total_data);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setParams({ ...params, keyword: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section]);

  const columns = [
    // {
    //   title: "Topic",
    //   dataIndex: "title",
    //   sorter: (a, b) => a.title.localeCompare(b.title),
    // },
    {
      title: "No",
      dataIndex: "key",
      rowScope: "row",
      render: (text, object, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Questions",
      dataIndex: "question",
      render: (question) => {
        return <div>{question || "-"}</div>;
      },
    },
    {
      title: "Answer",
      dataIndex: "answer",
      render: (answer) => <div>{answer?.replace(/(<([^>]+)>)/gi, "")}</div>,
    },
    // {
    //   title: "Status",
    //   dataIndex: "is_active",
    //   render: (is_active) => (
    //     <Tags
    //       title={is_active ? "Aktif" : "Non Aktif"}
    //       color={is_active ? "green" : "red"}
    //     />
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div className="flex items-center gap-x-1">
          <Buttons
            className="py-1 rounded-lg"
            icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => viewData(record)}
          />
          <Buttons
            className="py-1 rounded-lg"
            icon={<EditFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => editData(record)}
          />
          <Buttons
            className="py-1 rounded-lg"
            icon={<DeleteFilled className="text-gray-600 hover:text-primary" />}
            onClick={() => deleteData(record.id)}
          />
        </div>
      ),
    },
  ];

  const editData = (record) => {
    setChildData(record);
    setSection("edit");
  };

  const viewData = (record) => {
    setChildData(record);
    setSection("view");
  };

  const addData = () => {
    setSection("add");
  };

  const deleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteFAQ(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px]">List FAQ</h2>
            <Buttons
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addData()}
            >
              Add Data
            </Buttons>
          </div>
          <div className="body">
            <div className="mb-2 md:w-80 w-32">
              <InputSearch onChange={onSearch} />
            </div>
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
