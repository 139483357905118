import React, { useState } from "react";
import { Upload, Row, Col, Modal } from "antd";
import { MdOutlineFileUpload } from "react-icons/md";

const { Dragger } = Upload;

const OfficeImageUpload = ({ fileList, setFileList }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item !== file);
    setFileList(updatedFileList);
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      file.url = e.target.result;
      setFileList((prevFileList) => [...prevFileList, file]);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const draggerProps = {
    name: "file",
    multiple: true,
    fileList: [],
    beforeUpload: beforeUpload,
    listType: "picture-card",
    maxCount: 5,
  };

  return (
    <Row gutter={16} className="items-start">
      <Col span={12}>
        <div className="text-lg font-bold text-baseColor mb-2">Upload Photo</div>
        <Dragger {...draggerProps} className="w-full !bg-white">
          <p className="ant-upload-drag-icon text-center flex items-center justify-center">
            <MdOutlineFileUpload className="text-primary w-6 h-6 text-center" />
          </p>
          <p className="ant-upload-text">
            <span className="font-bold text-primary">Drag and Drop</span> photos here
          </p>
          <p className="ant-upload-hint">PNG, JPG, Max Size 2MB</p>
        </Dragger>
      </Col>
      <Col span={12}>
        <div className="text-lg font-bold text-baseColor mb-2">
          Uploaded Photos ({fileList.length})
        </div>
        <div className="flex flex-wrap gap-2">
          {fileList.map((file, index) => (
            <div key={index} className="relative group">
              <img
                src={file.url}
                alt={`uploaded-${index}`}
                className={`w-24 h-24 object-cover cursor-pointer rounded-md ${index === 0 ? "border-2 border-[#D36161]" : ""}`}
                onClick={() => handlePreview(file)}
              />
              {index === 0 && (
                <div className="absolute top-0 left-0 bg-blue-500 text-white text-[10px] px-1 py-0.5 bg-[#D36161] rounded-e-md rounded-tl-md">
                  Main Photo
                </div>
              )}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove(file);
                }}
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity pb-1"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </Col>
      <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Row>
  );
};

export default OfficeImageUpload;
