import React, { useState, useEffect } from "react";
import { Table, Tabs } from "antd";
import _ from "lodash";
import { Buttons, CustomPagination, Tags, InputSearch } from "../../../components/atoms/index";
import { EyeFilled, PlusOutlined, DeleteFilled } from "@ant-design/icons";
import { BiDownload } from "react-icons/bi";
import {
  getListBooking,
  downloadListBooking,
  getBookingDetail,
  deleteBooking,
} from "../../../services/api";
import BookingDetails from "./BookingDetails/BookingDetails";
import moment from "moment";
import { downloadFile } from "../../../utils/Helper";
import { useSelector } from "react-redux";
import pluralize from "pluralize";
import { saveAs } from "file-saver";
import FormBooking from "./FormBooking";

export default function BookingList() {
  const [activeTab, setActiveTab] = useState("0");
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const distributor_id = useSelector((state) => state.auth.user.distributor_id);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "ord.created_at,desc",
    order_status: 0,
    no_order: "",
    customer_name: "",
    distributor: "",
    start_date: "",
    end_date: "",
    distributor_id: distributor_id ? distributor_id : "",
  });

  const roles = useSelector((state) => state.auth.user.role_slug);

  const activeTabData = (val) => {
    switch (activeTab) {
      case "0":
        setData(val);
        break;
      case "1":
        setData(val?.filter((item) => item.booking_status === 1));
        break;
      case "2":
        setData(val.filter((item) => item.booking_status === 2));
        break;
      case "3":
        setData(val.filter((item) => item.booking_status === 3));
        break;
      case "4":
        setData(val.filter((item) => item.booking_status === 4));
        break;
      case "10":
        setData(val.filter((item) => item.booking_status === 10));
        break;
      case "11":
        setData(val.filter((item) => item.booking_status === 11));
        break;
      case "12":
        setData(val.filter((item) => item.booking_status === 12));
        break;
      default:
    }
  };

  useEffect(() => {
    getDataBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, params]);

  const getDataBooking = () => {
    setLoading(true);
    getListBooking(params)
      .then(({ data: { data: res } }) => {
        if (Array.isArray(res.data)) {
          activeTabData(res?.data);
        } else {
          setData([res.data]);
        }
        setTotal(res.data.length);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e, type) {
    if (type === "no_order") {
      setParams({ ...params, no_order: e.target.value, offset: 0 });
    } else if (type === "customer_name") {
      setParams({ ...params, customer_name: e.target.value, offset: 0 });
    } else if (type === "distributor") {
      setParams({ ...params, distributor: e.target.value, offset: 0 });
    }
  }, 500);

  const onDateChange = (e) => {
    let start = "";
    let end = "";
    if (e) {
      start = e[0];
      end = e[1];
    }
    setParams({
      ...params,
      offset: 0,
      start_date: start,
      end_date: end,
    });
  };

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section]);

  function formatColorStatus(status) {
    switch (status) {
      case 1:
        return "purple"; // Waiting For Checkout
      case 2:
        return "orange"; // Waiting For Payment
      case 3:
        return "green"; // Success
      case 4:
        return "red"; // Failed
      case 10:
        return "yellow"; // Canceled by Customer
      case 11:
        return "yellow"; // Canceled by Midtrans
      case 12:
        return "gray"; // Expired
      default:
        return "gray"; // Unknown Status
    }
  }

  const translateBookingStatus = (status) => {
    switch (status) {
      case 1:
        return "Waiting For Checkout";
      case 2:
        return "Waiting For Payment";
      case 3:
        return "Success";
      case 4:
        return "Failed";
      case 10:
        return "Canceled by Customer";
      case 11:
        return "Canceled by Midtrans";
      case 12:
        return "Expired";
      default:
        return "Unknown Status";
    }
  };

  const translatePaymentStatus = (status) => {
    switch (status) {
      case 1:
        return "Waiting For Checkout";
      case 2:
        return "Waiting For Payment";
      case 3:
        return "Success";
      case 4:
        return "Failed";
      case 10:
        return "Canceled by Customer";
      case 11:
        return "Canceled by Midtrans";
      case 12:
        return "Expired";
      default:
        return "Unknown Status";
    }
  };

  const columns = [
    {
      title: "Booking Number",
      dataIndex: "booking_invoice",
      sorter: (a, b) => a.booking_invoice.localeCompare(b.booking_invoice),
    },
    // {
    //   title: "Payment Invoice",
    //   dataIndex: ["payment", "payment_invoice"],
    //   sorter: (a, b) =>
    //     a.payment.payment_invoice.localeCompare(b.payment.payment_invoice),
    //   render: (payment_invoice) => <div>{payment_invoice || "-"}</div>,
    // },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
    },
    {
      title: "Start Date",
      dataIndex: "booking_start_date",
      sorter: (a, b) => new Date(a.booking_start_date) - new Date(b.booking_start_date),
      render: (booking_start_date) => (
        <div>
          {moment(booking_start_date).utcOffset("+00:00").format("DD/MM/YYYY hh:mm A") || "-"}
        </div>
      ),
    },
    {
      title: "End Date",
      dataIndex: "booking_end_date",
      sorter: (a, b) => new Date(a.booking_end_date) - new Date(b.booking_end_date),
      render: (booking_end_date) => (
        <div>
          {moment(booking_end_date).utcOffset("+00:00").format("DD/MM/YYYY hh:mm A") || "-"}
        </div>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "payment_type",
      sorter: (a, b) => a.payment_type.localeCompare(b.payment_type),
      render: (payment_type) => (
        <div>{payment_type === "payment_gateway" ? "Payment Gateway" : "Unknown Payment Type"}</div>
      ),
    },
    // {
    //   title: "Lease Period",
    //   render: (_, record) => {
    //     let period = "";
    //     if (record.month_count === 1) {
    //       period = "1 month";
    //     } else if (record.month_count > 1) {
    //       period = `${record.month_count} ${pluralize(
    //         "month",
    //         record.month_count
    //       )}`;
    //     } else if (record.week_count === 1) {
    //       period = "1 week";
    //     } else if (record.week_count > 1) {
    //       period = `${record.week_count} ${pluralize(
    //         "week",
    //         record.week_count
    //       )}`;
    //     } else if (record.hour_count === 1) {
    //       period = "1 hour";
    //     } else if (record.hour_count > 1) {
    //       period = `${record.hour_count} ${pluralize(
    //         "hour",
    //         record.hour_count
    //       )}`;
    //     } else if (record.half_day_count === 1) {
    //       period = "1 half day";
    //     } else if (record.half_day_count > 1) {
    //       period = `${record.half_day_count} ${pluralize(
    //         "half day",
    //         record.half_day_count
    //       )}`;
    //     } else if (record.days_count === 1) {
    //       period = "1 day";
    //     } else if (record.days_count > 1) {
    //       period = `${record.days_count} ${pluralize(
    //         "day",
    //         record.days_count
    //       )}`;
    //     } else {
    //       period = "Unknown";
    //     }
    //     return <div>{period}</div>;
    //   },
    // },
    {
      title: "Booking Status",
      dataIndex: "booking_status",
      key: "booking_status",
      render: (booking_status) => {
        return (
          <Tags
            color={formatColorStatus(booking_status)}
            title={translateBookingStatus(booking_status)}
          ></Tags>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="text-left">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<DeleteFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => deleteData(record)}
            />
          </div>
        </>
      ),
    },
  ];

  const viewData = (record) => {
    getBookingDetail(record?.id).then(({ data: { data: res } }) => {
      setChildData(res);
      setSection("view");
    });
  };

  const deleteData = (record) => {
    if (window.confirm("Are you sure you want to delete this booking?")) {
      deleteBooking(record?.id)
        .then(() => {
          alert("Booking deleted successfully");
          // Add logic to refresh the list after deletion
          getDataBooking();
          setSection("default");
        })
        .catch((error) => {
          console.error("Error deleting booking:", error);
          alert("Failed to delete booking");
        });
    }
  };

  function changeTab(key) {
    const order_status = parseInt(key);
    setParams({ ...params, order_status, offset: 0 });
    setActiveTab(key);
  }

  const downloadFile = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/booking/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await response.blob();
      saveAs(blob, `REPORT_${moment().format("l")}.xlsx`);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  const addData = () => {
    setSection("add");
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px]">Booking List</h2>
            <div className="flex items-center gap-4">
              <Buttons
                type="success"
                icon={<BiDownload />}
                loading={loadingDownload}
                onClick={downloadFile}
              >
                Download Data
              </Buttons>
              {/* {roles !== "super-admin-distributor" && (
                <Buttons
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => addData()}
                >
                  Add Data
                </Buttons>
              )} */}
            </div>
          </div>
          <div className="body">
            <Tabs
              activeKey={activeTab}
              onChange={changeTab}
              items={[
                {
                  label: "Waiting for Checkout",
                  key: "1",
                },
                {
                  label: "Waiting for Payment",
                  key: "2",
                },
                {
                  label: "Success",
                  key: "3",
                },
                {
                  label: "Failed",
                  key: "4",
                },
                {
                  label: "Canceled by Customer",
                  key: "10",
                },
                {
                  label: "Canceled by Midtrans",
                  key: "11",
                },
                {
                  label: "Expired",
                  key: "12",
                },
              ]}
            />
            <div className="grid  gap-8 mb-4">
              <div>
                <div className="mb-2 md:w-80 w-32">Order Number</div>
                <InputSearch onChange={(e) => onSearch(e, "no_order")} />
              </div>
              {/* <div>
                <div className="mb-2">Customer Name</div>
                <InputSearch onChange={(e) => onSearch(e, "customer_name")} />
              </div> */}
              {/* <div>
                <div className="mb-2">Range Tanggal</div>
                <RangePicker
                  className="w-full"
                  onChange={(d, ds) => onDateChange(ds)}
                />
              </div> */}
            </div>
            {/* {params.order_status === 0 && data?.length > 0 && (
              <Alerts
                type="info"
                message="Jika pesanan tidak dikonfirmasi oleh distributor dalam waktu 3 hari, maka pesananan akan dibatalkan secara otomatis"
              />
            )} */}
            <div className="w-full overflow-x-auto">
              <Table dataSource={data} columns={columns} rowKey="id" loading={loading} />
            </div>
          </div>
        </div>
      )}
      {section === "view" && (
        <BookingDetails
          childData={childData}
          setSection={setSection}
          formatColorStatus={formatColorStatus}
          trigger={trigger}
          setTrigger={setTrigger}
        />
      )}
      {section === "add" && <FormBooking section={section} setSection={setSection} />}
    </>
  );
}
