import React from "react";
import { Layout, Menu, theme } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { items } from "../../data/data";

const { Sider } = Layout;

const SidebarContent = ({ currentPath }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const handleMenuClick = ({ key }) => {
    if (key) {
      navigate(key);
    }
  };

  return (
    <Sider
      theme="light"
      style={{
        overflowY: "scroll",
        left: 0,
        top: 0,
        bottom: 0,
        fontWeight: 500,
        zIndex: 1,
      }}
      width={300}
      className="overflow-y-auto"
    >
      <Menu
        theme="light"
        defaultSelectedKeys={[currentPath]}
        mode="inline"
        onClick={handleMenuClick}
        items={items.map((item) =>
          item.children
            ? {
              ...item,
              children: item.children.map((subItem) => ({
                ...subItem,
                content: (
                  <Link to={`/${subItem.label}`}>{subItem.label}</Link>
                ),
              })),
              content: item.label,
            }
            : {
              ...item,
              content: <Link to={`/${item.label}`}>{item.label}</Link>,
            }
        )}
      />
    </Sider>
  );
};

export default SidebarContent;
