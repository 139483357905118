import React from "react";
import { Button } from "antd";

function ButtonsPrimary({ shape, customClass = "", type, ...props }) {
  return (
    <Button
      className={`${shape ? "" : "rounded-md h-full flex justify-center items-center px-3"} ${customClass} custom-button`}
      {...props}
      type={type}
    />
  );
}

export default ButtonsPrimary;
