import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/admin/login";
    }
    return Promise.reject(error);
  }
);

export const get = (url, config) => {
  return axiosInstance.get(url, config);
};

export const post = (url, data, config) => {
  return axiosInstance.post(url, data, config);
};

export const put = (url, data, config) => {
  return axiosInstance.put(url, data, config);
};

export const patch = (url, data, config) => {
  return axiosInstance.patch(url, data, config);
};

export const delete_request = (url, config) => {
  return axiosInstance.delete(url, config);
};
