const validateMessage = {
  required: {
    message: "Required!",
    symbol: "*",
    symbolColor: "red",
  },
  types: {
    email: "Inappropriate email format!",
  },
  number: {
    range: "Minimum number is 1",
  },
};
export default validateMessage;

export const getLabel = (label, required) => {
  return required ? (
    <span className="font-medium text-md">
      {label}
      <span style={{ color: validateMessage.required.symbolColor }}>
        {validateMessage.required.symbol}
      </span>
    </span>
  ) : (
    <span className="font-medium text-md">{label}</span>
  );
};
