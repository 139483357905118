import React, { useEffect, useState } from "react";

import { Input, Switch, Col, Row, Form, notification, Select } from "antd";
import { Buttons, ButtonBack, Tags } from "../../../components/atoms/index";
import validateMessage from "../../../utils/validateMessage";
import Datetime from "react-datetime";

import { editData, AddData } from "../../../services/api";

import moment from "moment";
// import moment from "moment";

const { Option } = Select;

export default function FormSection(props) {
  const { setSection, section, setTrigger, trigger, childData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState();

  const onFinish = (values) => {
    const result = {
      ...values,
      role: values?.role?.toUpperCase(),
      phone_number: `+62${values.phone_number}`,
      date_of_birth: dateOfBirth,
    };
    if (section === "add") {
      setLoading(true);
      AddData(result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Added a New Admin!",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: err?.data?.stat_msg,
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (section === "edit") {
      setLoading(true);
      editData(childData.id, result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Success Edit Admin",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Email/Phone number already used!",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (section !== "view") {
      // fetchListRoles();
    }
    if (section === "edit") {
      form.setFieldsValue({
        ...childData,
        role_id: childData.role.id,
        phone_number: childData.phone_number.replace("+62", ""),
        date_of_birth: moment(childData?.date_of_birth),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fetchListRoles = () => {
  //   getListRoleAdmin().then((res) => {
  //     setListRoles(res.data.data);
  //   });
  // };

  // const onChange = (_, dateString) => {
  //   setDateOfBirth(dateString);
  // };

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add Admin"}
              {section === "view" && "Detail"}
              {section === "edit" && "Edit Admin"}
            </div>
          </div>
        </div>
        {section !== "view" ? (
          <div className="body-with-footer">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              validateMessages={validateMessage}
            >
              <div className="body-content">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Full Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Type your full name" />
                    </Form.Item>
                    <Form.Item
                      label="Mobile Phone Number"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          min: 8,
                          message: "Minimum 8 numbers!",
                        },
                        {
                          max: 13,
                          message: "Maximum 13 numbers!",
                        },
                        {
                          pattern: new RegExp(/^8/gi),
                          message: "Numbers must begin with an 8!",
                        },
                      ]}
                      onChange={(e) =>
                        form.setFieldsValue({
                          ...form.getFieldsValue,
                          phone_number: e.target.value.replace(/^0/gi, ""),
                        })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    >
                      <Input
                        placeholder="Type in your mobile number"
                        addonBefore="+62"
                        maxLength={13}
                      />
                    </Form.Item>
                    {section === "edit" && (
                      // <div className="flex flex-col gap-2 w-full">
                      //   <label>Status</label>
                      //   <div className="bg-green p-1 w-fit rounded-xl">
                      //     <span className="text-green-50 text-sm font-semibold">
                      //       ACTIVE
                      //     </span>
                      //   </div>
                      // </div>

                      <Form.Item
                        label="Status"
                        name="status"
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Switch />
                      </Form.Item>
                    )}

                    {section === "add" && (
                      <Form.Item
                        label="Role"
                        name="role"
                        rules={[
                          {
                            required: true,
                            type: "text",
                          },
                        ]}
                      >
                        {/* <Input placeholder="Type in your role" /> */}
                        <Select placeholder="Choose Role">
                          <Option value="superadmin">Super Admin</Option>
                        </Select>
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={12}>
                    {/* <Form.Item
                      label="Status"
                      name="is_active"
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Switch />
                    </Form.Item> */}
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                        },
                      ]}
                    >
                      <Input placeholder="Type in your email address" />
                    </Form.Item>
                    <Form.Item
                      className="w-full"
                      label="Date Birth"
                      name="date_of_birth"
                      rules={[
                        {
                          required: true,
                          type: "date",
                        },
                      ]}
                    >
                      <Datetime
                        closeOnSelect
                        inputProps={{
                          autoComplete: "off",
                          className:
                            "border-2 rounded-md w-full p-1 outline-none",
                          name: "date_of_birth",
                          placeholder: "Enter Date Birth",
                        }}
                        onChange={(e) => {
                          const m = moment(e)
                            .utc()
                            .format("YYYY-MM-DDTHH:mm:ss[Z]");
                          setDateOfBirth(m);
                        }}
                        dateFormat="YYYY-MM-DD"
                        value={childData?.date_of_birth}
                        timeFormat={false}
                      />
                    </Form.Item>

                    {section === "add" && (
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input.Password placeholder="Type Password" />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="footer">
                <Buttons onClick={() => setSection("default")}>Cancel</Buttons>
                <Form.Item type="button">
                  <Buttons type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Buttons>
                </Form.Item>
              </div>
            </Form>
          </div>
        ) : (
          <div className="body">
            <Row>
              <Col span={12}>
                <div className="text-gray-500 mb-2">Fullname</div>
                <div className="mb-4">{childData.name}</div>
                <div className="text-gray-500 mb-2">Email</div>
                <div className="mb-4">{childData.email}</div>
                <div className="text-gray-500 mb-2">Role</div>
                <Tags color="purple" title={childData?.role} />
              </Col>
              <Col span={12}>
                <div className="text-gray-500 mb-2">Date Birth</div>
                <div className="mb-4">
                  {moment(childData.date_of_birth).format("L")}
                </div>
                <div className="text-gray-500 mb-2">Mobile Phone Number</div>
                <div className="mb-4">{childData.phone_number}</div>
                {/* <div className="text-gray-500 mb-2">Status</div>
                <Tags
                  color={childData.is_active ? "green" : "red"}
                  title={childData.is_active ? "Aktif" : "Tidak Aktif"}
                /> */}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}
