import React, { useEffect, useState } from "react";
import {
  Input,
  Col,
  Row,
  Form,
  notification,
  Switch,
  Image,
  Button,
  Upload,
} from "antd";
import { Buttons, ButtonBack } from "../../../components/atoms/index";
import validateMessage from "../../../utils/validateMessage";
import { postBanner, putBanner, uploadGeneral } from "../../../services/api";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export default function FormSection(props) {
  const { setSection, section, setTrigger, trigger, childData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const { title, is_active, upload, description } = values;
    let uploadRes = null;
    // check if file updated
    if (upload[0].originFileObj) {
      const formData = new FormData();
      formData.append("file", upload[0].originFileObj);
      await uploadGeneral(formData)
        .then((res) => {
          uploadRes = res.data.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    }

    const result = {
      title,
      is_active,
      description,
      image: uploadRes ? uploadRes.file_path : childData.image,
      link: uploadRes ? uploadRes.file_url : childData.link,
    };

    if (section === "add") {
      setLoading(true);
      await postBanner(result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Added Banner",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Add Banner",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (section === "edit") {
      setLoading(true);
      await putBanner(childData.id, result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Edit Banners",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Edit Banner",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (section === "edit") {
      form.setFieldsValue({
        title: childData.title,
        description: childData.description,
        is_active: childData.is_active,
        upload: [
          {
            name: childData.image,
            url: childData.link,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add Banner"}
              {section === "view" && "Detail"}
              {section === "edit" && "Edit Banner"}
            </div>
          </div>
        </div>
        {section !== "view" ? (
          <div className="body-with-footer">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              validateMessages={validateMessage}
            >
              <div className="body-content">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Banner Title"
                      name="title"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Type the banner title" />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Type a description" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="upload"
                      label="Upload"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      extra="Dimensions 1300 x 432. Maximum size 1MB file format .jpg, jpeg, png"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Upload
                        name="file"
                        beforeUpload={() => {
                          return false;
                        }}
                        maxCount={1}
                      >
                        <Button
                          className="flex items-center"
                          icon={<UploadOutlined />}
                        >
                          Upload File
                        </Button>
                      </Upload>
                    </Form.Item>
                    {/* <Form.Item
                      label="Urutan"
                      name="sortBy"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="Pilih urutan" allowClear>
                        {listSort?.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item> */}
                    <Form.Item
                      label="Status"
                      name="is_active"
                      valuePropName="checked"
                      initialValue={false}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="footer">
                <Buttons onClick={() => setSection("default")}>Cancel</Buttons>
                <Form.Item type="button">
                  <Buttons type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Buttons>
                </Form.Item>
              </div>
            </Form>
          </div>
        ) : (
          <div className="body">
            <Row>
              <Col span={12}>
                <div className="flex items-center gap-4 text-lg mb-3">
                  <Image src={childData.link} height={50} width={130} />
                  <span>{childData.title}</span>
                </div>
                <div className="text-gray-500 mb-2">Description</div>
                <div>{childData.description}</div>
              </Col>
              <Col span={12}>
                <div className="text-gray-500 mb-2">Order</div>
                <div>{childData.id}</div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}
