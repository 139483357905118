import ButtonsPrimary from "./ButtonsPrimary";
import ButtonBack from "./ButtonBack";
import Alerts from "./Alerts";
import CustomPagination from "./CustomPagination";
import InputSearch from "./InputSearch";
import Tags from "./Tags";
import FieldButton from "./FieldButton";
import Images from "./Images";
import UploadButton from "./UploadButton";

export {
  ButtonsPrimary as Buttons,
  ButtonBack,
  Alerts,
  CustomPagination,
  InputSearch,
  Tags,
  FieldButton,
  Images,
  UploadButton,
};
