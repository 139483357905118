import React, { useState, useEffect } from "react";
import { Table, Modal, notification } from "antd";
import _ from "lodash";
import {
  Buttons,
  CustomPagination,
  Images,
  InputSearch,
  Tags,
} from "../../../components/atoms";
import {
  PlusOutlined,
  EyeFilled,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FormSection from "./FormSection";
import {
  getDiscount,
  deleteDiscount,
  getDiscountDetail,
} from "../../../services/api";
import { formatPromoType, formatPromoTypeColor } from "../../../utils/Helper";
import moment from "moment";

const { confirm } = Modal;

export default function ManageDiscount() {
  const [section, setSection] = useState("default");
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  // const [DiscountDetail, setDiscountDetail] = useState([]);
  const [childData, setChildData] = useState({});
  const [totalData, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10,
    order: "id,desc",
    keyword: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, params]);

  // useEffect(() => {
  //   getDataDetail(params);
  // }, [params]);

  const getData = () => {
    setLoading(true);
    getDiscount(params)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setTotal(res.data.total_data);
      })
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const getDataDetail = async (params) => {
  //   setLoading(true);
  //   getDiscountDetail(params)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       throw new Error(err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  function onPageChange(page, pageSize) {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    setParams({ ...params, offset, limit });
  }

  const onSearch = _.debounce(function (e) {
    setParams({ ...params, keyword: e.target.value });
  }, 500);

  useEffect(() => {
    if (section === "default") {
      setChildData({});
    }
  }, [section, setSection]);

  const columns = [
    {
      title: "Promo Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="flex items-center gap-2">
          {/* <Images src={record.image} width={60} height={60} /> */}
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: "Promo Type",
      dataIndex: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text) => (
        <Tags
          title={formatPromoType(text)}
          color={formatPromoTypeColor(text)}
        />
      ),
    },
    {
      title: "Period",
      dataIndex: "start_date",
      render: (text, record) => (
        <div>
          {moment(record.start_date).format("L") +
            " - " +
            moment(record.end_date).format("L")}
        </div>
      ),
    },
    // {
    //   title: "Discount",
    //   dataIndex: "amount",
    //   render: (record, rowData) => {
    //     if (rowData.type === "direct_discount") {
    //       return <div>{record}%</div>;
    //     } else if (rowData.type === "buy_x_get_x") {
    //       return (
    //         <div>
    //           Buy {rowData.buy_x || 0} Get {rowData.get_x || 0}
    //         </div>
    //       );
    //     } else {
    //       return <div>{record}</div>; // Anda dapat menambahkan logika lain jika ada tipe promo lainnya
    //     }
    //   },
    // },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-1">
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EyeFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => viewData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={<EditFilled className="text-gray-600 hover:text-primary" />}
              onClick={() => editData(record)}
            />
            <Buttons
              className="py-1 rounded-lg hover:border-primary"
              icon={
                <DeleteFilled className="text-gray-600 hover:text-primary" />
              }
              onClick={() => deleteData(record.id)}
            />
          </div>
        </>
      ),
    },
  ];

  const editData = (record) => {
    getDiscountDetail(record.id)
      .then((res) => {
        setChildData(res.data.data);
        setSection("edit");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const viewData = (record) => {
    getDiscountDetail(record.id)
      .then((res) => {
        console.log(res.data.data);
        setChildData(res.data.data);
        setSection("view");
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const addData = () => {
    setSection("add");
  };

  const deleteData = (id) => {
    confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this data? This action cannot be undone.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          deleteDiscount(id)
            .then((res) => {
              notification.success({
                message: "Success!",
                description: "Successfully delete data",
                placement: "topRight",
              });
              getData();
              resolve(res);
            })
            .catch((err) => {
              notification.error({
                message: "Failed!",
                description: "Failed to delete data",
                placement: "topRight",
              });
              reject(err);
            });
        });
      },
    });
  };

  return (
    <>
      {section === "default" && (
        <div className="content-section">
          <div className="header">
            <h2 className="lg:text-[28px] text-[20px]">Manage Discount</h2>
            <Buttons
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => addData()}
            >
              Add Data
            </Buttons>
          </div>
          <div className="body">
            <div className="mb-2 md:w-80 w-32">
              <InputSearch onChange={onSearch} />
            </div>
            <div className="w-full overflow-x-auto">
              <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
      {section !== "default" && (
        <FormSection
          setSection={setSection}
          section={section}
          setTrigger={setTrigger}
          trigger={trigger}
          childData={childData}
        />
      )}
    </>
  );
}
