import React from "react";
import { Tags } from "../../../components/atoms/index";
import {
  formatPromoType,
  formatPromoTypeColor,
  officeCategories,
  regionID,
} from "../../../utils/Helper";
import moment from "moment";

export default function OfficeDetail(props) {
  const { childData, DataRef } = props;

  return (
    <div className="pb-8 pt-2">
      <div>
        <h2 ref={DataRef} className="text-lg font-semibold text-primary mb-2">
          Discount Data
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-secondary">Discount Name</div>
            <div>{childData.name}</div>
          </div>
          <div>
            <div className="text-secondary">Promo Type</div>
            <Tags
              color={formatPromoTypeColor(childData.type)}
              title={formatPromoType(childData.type)}
            />
          </div>
          <div>
            <div className="text-secondary">Start Date</div>
            <div>
              {moment(childData.start_date).format("DD MMMM YYYY HH.mm.ss")}
            </div>
          </div>
          <div>
            <div className="text-secondary">End Date</div>
            <div>
              {moment(childData.end_date).format("DD MMMM YYYY HH.mm.ss")}
            </div>
          </div>
          {childData.type === "direct_discount" && (
            <div>
              <div className="text-secondary">Promo Amount</div>
              <div>{childData.amount}%</div>
            </div>
          )}
        </div>
        {childData.offices.map((office, index) => (
          <div key={index}>
            <h2 className="mt-6 text-lg font-semibold text-primary mb-2">
              {`Office Detail ${index + 1}`}
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-secondary">Office Name</div>
                <div>{office.office_detail.name}</div>
              </div>
              <div>
                <div className="text-secondary">Status</div>
                <Tags
                  title={office.office_detail.status ? "Active" : "Not Active"}
                  color={office.office_detail.status ? "green" : "red"}
                />
              </div>
              <div>
                <div className="text-secondary">Office Category</div>
                <div>
                  {officeCategories[office.office_detail.office_category]}
                </div>
              </div>
              <div>
                <div className="text-secondary">Region</div>
                <div>{regionID[office.office_detail.region_id]}</div>
              </div>
              {childData.type === "buy_x_get_x" && (
                <div>
                  <div className="text-secondary">
                    Minimum office lease (per-month)
                  </div>
                  <div>{office.buy_x} month</div>
                </div>
              )}
              {childData.type === "buy_x_get_x" && (
                <div>
                  <div className="text-secondary">
                    Period of lease acquired (per-month)
                  </div>
                  <div>{office.get_x} month</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
