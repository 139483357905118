import React, { useEffect, useRef, useState } from "react";
import { Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/icons/Logo.svg";
import { ChevronLeft, EnvelopeOpen } from "react-bootstrap-icons";
import validateMessage from "../../../utils/validateMessage";
import { forgotPassword } from "../../../services/api";
import ButtonsPrimary from "../../../components/atoms/ButtonsPrimary";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const emailRef = useRef(null);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const onFinish = (values) => {
    const { email } = values;
    setEmail(email);
    const payload = {
      email,
      callback_url: window.location.hostname,
    };
    forgotPassword(payload)
      .then((res) => {
        if (res) {
          setEmailSent(true);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Failed!",
          description: "Email not found!",
          placement: "topRight",
        });
      });
  };

  const handleResendEmail = () => {
    setEmailSent(false);
    form.resetFields();
  };

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <div
          className="card shadow-lg bg-white rounded-lg p-16 w-[500px]"
          style={{ marginTop: "-190px" }}
        >
          {!emailSent ? (
            <>
              <h3 className="text-2xl text-gray-900 font-semibold pt-2 pb-1 mb-4">
                Reset Password
              </h3>
              <p className="text-md font-light text-gray-500 mb-8">
                Enter your email and we'll send the details on how to reset your
                password.
              </p>
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateMessages={validateMessage}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Type Email" ref={emailRef} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <ButtonsPrimary
                      type="primary"
                      htmlType="submit"
                      customclass="w-full"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      Reset Password
                    </ButtonsPrimary>
                  )}
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <div className="flex justify-center items-center mb-8">
                <div className="bg-primary/10 rounded-full p-6 inline-flex justify-center items-center">
                  <EnvelopeOpen className="text-primary w-10 h-10" />
                </div>
              </div>
              <h3 className="text-2xl text-gray-900 font-semibold pt-2 pb-1 mb-4">
                Email sent!
              </h3>
              <p className="text-md font-light text-gray-500 mb-8">
                We've sent a confirmation email to {email}. Please follow the
                instructions on how to reset your password.
              </p>
              <ButtonsPrimary
                type="primary"
                customclass="w-full bg-lightRed text-primary"
                onClick={handleResendEmail}
              >
                Resend Email
              </ButtonsPrimary>
            </>
          )}
          <div
            className="flex items-center cursor-pointer mt-3"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className="text-primary" />
            <span className="text-primary ml-3 hover:underline">
              Back to Login
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
