import { useState } from "react";
import { ButtonBack, Buttons } from "../../../components/atoms";
import { Formik, Form, Field } from "formik";
import { Row, Col, notification } from "antd";
import TextEditor from "../../../components/molecules/TextEditor";
import { postContactUs, putContactsUs } from "../../../services/api";

export default function FormSection({
  setSection,
  section,
  setTrigger,
  trigger,
  childData,
}) {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");

  const handleOnSubmit = (values) => {
    const params = {
      ...values,
      phone_number: `+62${values.phone_number}`,
      whatsapp: `+62${values.whatsapp}`,
    };

    if (section === "add") {
      setLoading(true);
      postContactUs(params)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Add New Contact",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Add New Contact",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (section === "edit") {
      setLoading(true);
      putContactsUs(childData.id, params)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success!",
              description: "Successfully Edit Contact",
              placement: "topRight",
            });
            setSection("default");
            setTrigger(!trigger);
          }
        })
        .catch((err) => {
          notification.error({
            message: "Failed!",
            description: "Failed to Edit Contact",
            placement: "topRight",
          });
          throw new Error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  console.log("childData :", childData);

  return (
    <div className="content-section">
      <div className="header">
        <div className="flex items-center">
          <ButtonBack onClick={() => setSection("default")} />
          <div className="text-3xl font-bold">
            {section === "add" && "Add Contact"}
            {section === "view" && "Detail"}
            {section === "edit" && "Edit Contact"}
          </div>
        </div>
      </div>

      {section !== "view" ? (
        <div className="body-with-footer">

          <Formik
            enableReinitialize
            onSubmit={handleOnSubmit}
            initialValues={{
              name: childData.name,
              phone_number: childData.phone_number?.replace("+62", ""),
              whatsapp: childData.whatsapp?.replace("+62", ""),
            }}
          >
            {() => {
              return (
                <Form className="">
                  <Row gutter={16} className="p-9">
                    <Col span={12} className="">
                      <div className="flex flex-col gap-1">
                        <label className="font-bold">Contact Name</label>
                        <Field
                          name="name"
                          type="text"
                          placeholder="Type a name"
                          className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                      </div>
                      <div className="flex flex-col gap-1 mt-6">
                        <label className="font-bold">Phone Number</label>
                        <div className="w-full flex gap-2 items-center bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                          <span>+62</span>
                          <Field
                            name="phone_number"
                            type="tel"
                            placeholder="Type a WhatsApp"
                            pattern="[0-9]*"
                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                            className="w-full border-none outline-none"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flex flex-col gap-1">
                        <label className="font-bold">WhatsApp Number</label>
                        <div className="w-full flex gap-2 items-center bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                          <span>+62</span>
                          <Field
                            name="whatsapp"
                            type="tel"
                            placeholder="Type a phone number"
                            pattern="[0-9]*"
                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                            className="w-full border-none outline-none"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="footer">
                    <Buttons onClick={() => setSection("default")}>
                      Cancel
                    </Buttons>

                    <button
                      type="submit"
                      className="bg-primary w-16 rounded-md"
                    >
                      <span className="text-white">Save</span>
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div className="body pb-16">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="text-gray-500 mb-2">Name</div>
              <div className="mb-4">{childData?.name || "-"}</div>
            </div>
            <div>
              <div className="text-gray-500 mb-2">WhatsApp</div>
              <div className="mb-4">
                Click to redirect:{" "}
                {childData?.whatsapp ? (
                  <a href={`https://wa.me/${childData.whatsapp}`}>
                    {childData.whatsapp}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div>
              <div className="text-gray-500 mb-2">Phone Number</div>
              <div className="mb-4">{childData?.phone_number || "-"}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
