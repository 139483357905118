import React from "react";
import "../../App.scss";

const Tags = ({ color, title }) => {
  return (
    <div
      className={`custom-tag custom-tag-${color} inline-block px-3 py-1 rounded-full text-xs font-medium`}
    >
      {title}
    </div>
  );
};

export default Tags;
