import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Upload,
  Modal,
  notification,
  Select,
  Switch,
  DatePicker,
} from "antd";
import _ from "lodash";
import {
  ButtonBack,
  Buttons,
  UploadButton,
} from "../../../../components/atoms/index";
import { getLabel } from "../../../../utils/validateMessage";
import validateMessage from "../../../../utils/validateMessage";
import ServicedOfficeDetail from "./ServicedOfficeDetail";
import {
  postOffice,
  putOffice,
  getRegionIdAndName,
  getOfficeCategory,
} from "../../../../services/api";
import {
  formatRupiah,
  officeCategories,
  regionID,
} from "../../../../utils/Helper";

const { TextArea } = Input;
const { Option } = Select;

export default function FormSection(props) {
  const { setSection, section, childData } = props;
  const [form] = Form.useForm();
  const D = Form.useWatch("classification_id", form);
  const NUMBER_VALIDATOR = /^[0-9]*$/;
  const [regionID, setRegionID] = useState([]);
  const [categoryID, setCategoryID] = useState([]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevD = usePrevious(D);

  // list ref
  const DataRef = useRef();
  const DescriptionRef = useRef();
  const OfficeDetailRef = useRef();
  const PriceRef = useRef();

  // handle file upload
  const [fileList, setFileList] = useState([]);
  const [modalPreview, setModalPreview] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchRegionID();
    fetchOfficeCategory();
  }, []);

  const fetchRegionID = () => {
    getRegionIdAndName()
      .then((regionData) => {
        setRegionID(regionData);
      })
      .catch((err) => {
        console.error("Error fetching region:", err);
      });
  };

  const fetchOfficeCategory = () => {
    getOfficeCategory()
      .then((categoryData) => {
        setCategoryID(categoryData);
      })
      .catch((err) => {
        console.error("Error fetching office category:", err);
      });
  };

  //handle state number
  const [pricePerHour, setPricePerHour] = useState(
    section === "edit" ? childData.price.price_per_hour : 0
  );
  const [priceDaily, setPriceDaily] = useState(
    section === "edit" ? childData.price.price_daily : 0
  );

  const [priceMinimum, setPriceMinimum] = useState(
    section === "edit" ? childData.price.price_minimum : 0
  );
  const [priceWeekly, setPriceWeekly] = useState(
    section === "edit" ? childData.price.price_weekly : 0
  );
  const [priceMonthly, setPriceMonthly] = useState(
    section === "edit" ? childData.price.price_weekly : 0
  );
  const [capacityChair, setCapacityChair] = useState(
    section === "edit" ? childData.office_details.capacity_chair : 0
  );
  const [capacityDesk, setCapacityDesk] = useState(
    section === "edit" ? childData.office_details.capacity_desk : 0
  );
  const [capacityPerson, setCapacityPerson] = useState(
    section === "edit" ? childData.office_details.capacity_person : 0
  );

  useEffect(() => {
    if (section === "edit") {
      const newArr = [...childData.office_catalogue];
      const dataArr = [];
      newArr.forEach((item) => {
        if (item) {
          const data = {
            // uid: item.office_id,
            url: item.url,
          };
          dataArr.push(data);
        }
      });
      setFileList(dataArr);
      form.setFieldsValue({
        ...childData,
        office_category: childData.office_category,
        region_id: childData.region_id,
        name: childData.name,
        description: childData.description,
        service_name: childData.office_details.service_name,
        location: childData.office_details.location,
        address: childData.office_details.address,
        capacity_chair: childData.office_details.capacity_chair,
        capacity_desk: childData.office_details.capacity_desk,
        capacity_person: childData.office_details.capacity_person,
        price_per_hour: childData.price.price_per_hour,
        price_daily: childData.price.price_daily,
        price_half_day: childData.price.price_half_day,
        price_minimum: childData.price.price_minimum,
        price_weekly: childData.price.price_weekly,
        price_monthly: childData.price.price_monthly,
        status: childData.status,
        office_catalogue: dataArr,
      });
    } // const url = `${REACT_APP_API_URL}/offices?page=${offset}&page_size=${limit}&search=${keyword}`;
    if (section !== "view") {
      // getAllOptionValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normFile = (e) => {
    return e?.fileList;
  };

  // set input
  const [inputPricePerHour, setInputPricePerHour] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_per_hour !== undefined
        ? childData.price.price_per_hour
        : "",
  });

  const [inputPriceDaily, setInputPriceDaily] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_daily !== undefined
        ? childData.price.price_daily
        : "",
  });
  const [inputPriceMinimum, setInputPriceMinimum] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_minimum !== undefined
        ? childData.price.price_minimum
        : "",
  });
  const [inputPriceWeekly, setInputPriceWeekly] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_weekly !== undefined
        ? childData.price.price_weekly
        : "",
  });
  const [inputPriceMonthly, setInputPriceMonthly] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_monthly !== undefined
        ? childData.price.price_monthly
        : "",
  });
  const [inputCapacityChair, setInputCapacityChair] = useState({
    status: "success",
    message: "",
    value:
      childData.office_details &&
        childData.office_details.capacity_chair !== undefined
        ? childData.office_details.capacity_chair
        : "",
  });
  const [inputCapacityDesk, setInputCapacityDesk] = useState({
    status: "success",
    message: "",
    value:
      childData.office_details &&
        childData.office_details.capacity_desk !== undefined
        ? childData.office_details.capacity_desk
        : "",
  });
  const [inputCapacityPerson, setInputCapacityPerson] = useState({
    status: "success",
    message: "",
    value:
      childData.office_details &&
        childData.office_details.capacity_person !== undefined
        ? childData.office_details.capacity_person
        : "",
  });

  const onFinish = (values) => {
    const {
      name,
      office_category,
      description,
      status,
      region_id,
      service_name,
      location,
      address,
    } = values;

    let result = {
      name,
      office_category: parseInt(office_category),
      description,
      status,
      region_id: parseInt(region_id),
      price_per_hour: inputPricePerHour.value,
      price_daily: inputPriceDaily.value,
      price_minimum: inputPriceMinimum.value,

      price_weekly: inputPriceWeekly.value,
      price_monthly: inputPriceMonthly.value,
      location,
      service_name,
      capacity_person: inputCapacityPerson.value,
      capacity_desk: inputCapacityDesk.value,
      capacity_chair: inputCapacityChair.value,
      address,
      office_catalogue: fileList,
    };
    console.log("onfinish", isLoading);

    setIsLoading(true);

    if (section === "edit") {
      console.log("form edit", result);
      putOffice(childData.id, result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success",
              description: "Successfully change the product!",
            });
            setSection("default");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (section === "add") {
      console.log("form add", result);
      postOffice(result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success",
              description: "Successfully added office/service!",
            });
            setSection("default");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const localUrl = e.target.result;
      file.url = localUrl;

      setFileList((prevFileList) => [...prevFileList, file]);
    };

    reader.readAsDataURL(file);

    return false;
  };

  const handlePreview = (file) => {
    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj);
    }
    setCurrentFile(file.preview);
    setModalPreview(true);
  };

  const handleRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item !== file);
    setFileList(updatedFileList);
  };

  const handleInputNumber = (event, setInputAmount, setPriceFunction) => {
    const realtimeValue = Number(event.target.value.replace(/[^0-9]/g, ""));
    setPriceFunction(realtimeValue);
    if (!NUMBER_VALIDATOR.test(realtimeValue)) {
      setInputAmount({
        status: "error",
        message: "Please only enter numbers!",
        value: realtimeValue,
      });
    } else if (event.target.value === "") {
      setInputAmount({
        status: "error",
        message: "Required!",
        value: event.target.value,
      });
    } else {
      setInputAmount({
        status: "success",
        message: "",
        value: realtimeValue,
      });
    }
  };

  return (
    <>
      <div className="content-section">
        <div className="header">
          <div className="flex items-center">
            <ButtonBack onClick={() => setSection("default")} />
            <div className="header-title">
              {section === "add" && "Add Serviced Office"}
              {section === "view" && "Detail"}
              {section === "edit" && "Edit Serviced Office"}
            </div>
          </div>
        </div>
        <div className="body max-body-height pt-3 pb-0">
          {section !== "view" ? (
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              validateMessages={validateMessage}
            >
              <div className="relative scroll-custom">
                <div ref={DataRef} className="fit-scroll py-2">
                  <div className="text-lg font-semibold text-primary mb-2">
                    Serviced Office Data
                  </div>
                  <Row>
                    <Form.Item
                      label={getLabel("Attach Office Picture", true)}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      extra="Recommended dimensions 700 x 700. Upload files with a maximum size of 1MB with file formats .jpg, jpeg, png"
                      rules={[
                        {
                          // required: true,
                        },
                      ]}
                    >
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onRemove={handleRemove}
                        beforeUpload={beforeUpload}
                        maxCount={5}
                      >
                        {fileList.length >= 5 ? null : <UploadButton />}
                      </Upload>
                    </Form.Item>
                    <Modal
                      open={modalPreview}
                      footer={null}
                      onCancel={() => setModalPreview(false)}
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={currentFile}
                        alt="file"
                      />
                    </Modal>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label={getLabel("Office Name", true)}
                        name="name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Insert office name" />
                      </Form.Item>
                      <Form.Item
                        label={getLabel("Office Region", true)}
                        name="region_id"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Choose region">
                          {regionID.map((item) => (
                            <Option value={item.regionID} key={item.regionID}>
                              {item.regionName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={getLabel("Office Category", true)}
                        name="office_category"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Choose office category">
                          {categoryID.map((item) => (
                            <Option
                              value={item.categoryID}
                              key={item.categoryID}
                            >
                              {item.categoryName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Status"
                        name="status"
                        valuePropName="checked"
                        initialValue={false}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div ref={DescriptionRef} className="fit-scroll py-2">
                  <div className="text-lg font-semibold text-primary py-2">
                    Description
                  </div>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Write a brief description of the serviced office"
                        name="description"
                      >
                        <TextArea rows={4} placeholder="Insert description" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div ref={OfficeDetailRef} className="fit-scroll py-2">
                  <div className="text-lg font-semibold text-primary mb-2">
                    Office Detail
                  </div>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label={getLabel("Service Name", true)}
                        name="service_name"
                        rules={[
                          {
                            // required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Insert service name" />
                      </Form.Item>
                      <Form.Item
                        label={getLabel("Address", true)}
                        name="address"
                        rules={[
                          {
                            // required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Insert office address" />
                      </Form.Item>
                      <Form.Item
                        label={getLabel("Capacity (Desk)", true)}
                        validateStatus={inputCapacityDesk.status}
                        help={inputCapacityDesk.message}
                        rules={[
                          {
                            required: true,
                          },
                          {
                            message: validateMessage.number.range,
                          },
                        ]}
                      // initialValue={0}
                      >
                        <Input
                          onChange={(e) =>
                            handleInputNumber(
                              e,
                              setInputCapacityDesk,
                              setCapacityDesk
                            )
                          }
                          value={
                            capacityDesk === null ||
                              capacityDesk === undefined ||
                              capacityDesk === 0
                              ? ""
                              : formatRupiah(capacityDesk)
                          }
                          id="capacity_desk"
                          placeholder="Insert desk capacity (e.g. 5)"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={getLabel("Phone Number", true)}
                        name="location"
                        rules={[
                          {
                            // required: true,
                          },
                          {
                            // pattern: new RegExp(/^[1-9]\d*$/),
                            // message: validateMessage.number.range,
                          },
                        ]}
                      >
                        <Input placeholder="Insert phone number" />
                      </Form.Item>
                      <Form.Item
                        label={getLabel("Capacity (Chair)", true)}
                        validateStatus={inputCapacityChair.status}
                        help={inputCapacityChair.message}
                        rules={[
                          {
                            required: true,
                          },
                          {
                            message: validateMessage.number.range,
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            handleInputNumber(
                              e,
                              setInputCapacityChair,
                              setCapacityChair
                            )
                          }
                          value={
                            capacityChair === null ||
                              capacityChair === undefined ||
                              capacityChair === 0
                              ? ""
                              : formatRupiah(capacityChair)
                          }
                          id="capacity_chair"
                          placeholder="Insert chair capacity (e.g. 5)"
                        />
                      </Form.Item>
                      <Form.Item
                        label={getLabel("Capacity (Person)", true)}
                        validateStatus={inputCapacityPerson.status}
                        help={inputCapacityPerson.message}
                        rules={[
                          {
                            required: true,
                          },
                          {
                            message: validateMessage.number.range,
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            handleInputNumber(
                              e,
                              setInputCapacityPerson,
                              setCapacityPerson
                            )
                          }
                          value={
                            capacityPerson === null ||
                              capacityPerson === undefined ||
                              capacityPerson === 0
                              ? ""
                              : formatRupiah(capacityPerson)
                          }
                          id="capacity_person"
                          placeholder="Insert person capacity (e.g. 5)"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div ref={PriceRef} className="fit-scroll py-2">
                  <div className="text-lg font-semibold text-primary mb-2">
                    Price
                  </div>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label={getLabel("Price Per Hour", true)}
                        validateStatus={inputPricePerHour.status}
                        help={inputPricePerHour.message}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        initialValue={0}
                      >
                        <Input
                          addonBefore="IDR"
                          onChange={(e) =>
                            handleInputNumber(
                              e,
                              setInputPricePerHour,
                              setPricePerHour
                            )
                          }
                          value={formatRupiah(pricePerHour)}
                          id="price_per_hour"
                          placeholder="0"
                        />
                      </Form.Item>
                      <Form.Item
                        label={getLabel("Daily Price", true)}
                        validateStatus={inputPriceDaily.status}
                        help={inputPriceDaily.message}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        initialValue={0}
                      >
                        <Input
                          addonBefore="IDR"
                          onChange={(e) =>
                            handleInputNumber(
                              e,
                              setInputPriceDaily,
                              setPriceDaily
                            )
                          }
                          value={formatRupiah(priceDaily)}
                          id="price_daily"
                          placeholder="0"
                        />
                      </Form.Item>
                      <Form.Item
                        label={getLabel("Monthly Price", true)}
                        validateStatus={inputPriceMonthly.status}
                        help={inputPriceMonthly.message}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        initialValue={0}
                      >
                        <Input
                          addonBefore="IDR"
                          onChange={(e) =>
                            handleInputNumber(
                              e,
                              setInputPriceMonthly,
                              setPriceMonthly
                            )
                          }
                          value={formatRupiah(priceMonthly)}
                          id="price_monthly"
                          placeholder="0"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="border-t-2 -mx-5">
                <div className="flex justify-end gap-3 px-5 pt-4">
                  <Buttons onClick={() => setSection("default")}>
                    Cancel
                  </Buttons>
                  <Form.Item type="button">
                    <Buttons
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Save
                    </Buttons>
                  </Form.Item>
                </div>
              </div>
            </Form>
          ) : (
            <ServicedOfficeDetail
              {...props}
              DataRef={DataRef}
              DescriptionRef={DescriptionRef}
            // TagRef={TagRef}
            // VariantRef={VariantRef}
            // PbfRef={PbfRef}
            />
          )}
        </div>
      </div>
    </>
  );
}
