import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OfficeImageUpload from "../../../../components/atoms/OfficeImageUpload";
import { ArrowLeftShort } from "react-bootstrap-icons";
import {
  Form,
  Input,
  Col,
  Row,
  Upload,
  Modal,
  notification,
  Select,
  Switch,
  message,
  Divider,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { MdOutlineFileUpload } from "react-icons/md";
import _ from "lodash";
import { ButtonBack, Buttons, UploadButton } from "../../../../components/atoms/index";
import { getLabel } from "../../../../utils/validateMessage";
import validateMessage from "../../../../utils/validateMessage";
import OfficeDetail from "./OfficeDetail";
import {
  postOffice,
  putOffice,
  getRegionIdAndName,
  getOfficeCategory,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { formatRupiah } from "../../../../utils/Helper";
import Map from "../../../../components/atoms/Map";

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

export default function FormSection(props) {
  const { setSection, section, childData } = props;
  const [form] = Form.useForm();
  // const watchForm = Form.useWatch([], form);
  // const roles = useSelector((state) => state.auth.user.role_slug);
  const D = Form.useWatch("classification_id", form);
  const NUMBER_VALIDATOR = /^[0-9]*$/;
  const [regionID, setRegionID] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [statusSwitchChecked, setStatusSwitchChecked] = useState(false);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevD = usePrevious(D);

  // list ref
  const DataRef = useRef();
  const DescriptionRef = useRef();
  const OfficeDetailRef = useRef();
  const PriceRef = useRef();
  const PriceExternalRef = useRef();

  // handle file upload
  const [fileList, setFileList] = useState([]);
  const [modalPreview, setModalPreview] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: -6.2,
    lng: 106.816666,
  });

  useEffect(() => {
    fetchRegionID();
    fetchOfficeCategory();
  }, []);

  const fetchRegionID = () => {
    getRegionIdAndName()
      .then((regionData) => {
        setRegionID(regionData);
      })
      .catch((err) => {
        console.error("Error fetching region:", err);
      });
  };

  const fetchOfficeCategory = () => {
    getOfficeCategory()
      .then((categoryData) => {
        setCategoryID(categoryData);
      })
      .catch((err) => {
        console.error("Error fetching office category:", err);
      });
  };

  //handle state number
  const [pricePerHour, setPricePerHour] = useState(
    section === "edit" ? childData.price.price_per_hour : 0,
  );
  const [priceDaily, setPriceDaily] = useState(
    section === "edit" ? childData.price.price_daily : 0,
  );

  // const [priceMinimum, setPriceMinimum] = useState(
  //   section === "edit" ? childData.price.price_minimum : 0
  // );
  const [priceWeekly, setPriceWeekly] = useState(
    section === "edit" ? childData.price.price_weekly : 0,
  );
  const [priceMonthly, setPriceMonthly] = useState(
    section === "edit" ? childData.price.price_monthly : 0,
  );
  const [priceOvertime, setPriceOvertime] = useState(
    section === "edit"
      ? childData.price.price_overtime || childData.price.price_per_hour_overtime || 0
      : 0,
  );
  const [pricePerHourExternal, setPricePerHourExternal] = useState(
    section === "edit" ? childData.price.price_per_hour_external : 0,
  );
  const [priceHalfDayExternal, setPriceHalfDayExternal] = useState(
    section === "edit" ? childData.price.price_half_day_external : 0,
  );
  const [pricePerDayExternal, setPricePerDayExternal] = useState(
    section === "edit" ? childData.price.price_per_day_external : 0,
  );
  const [pricePerHourOvertimeExternal, setPricePerHourOvertimeExternal] = useState(
    section === "edit" ? childData.price.price_per_hour_overtime_external : 0,
  );
  const [capacityChair, setCapacityChair] = useState(
    section === "edit" ? childData.office_details.capacity_chair : 0,
  );
  const [capacityDesk, setCapacityDesk] = useState(
    section === "edit" ? childData.office_details.capacity_desk : 0,
  );
  const [capacityPerson, setCapacityPerson] = useState(
    section === "edit" ? childData.office_details.capacity_person : 0,
  );

  useEffect(() => {
    if (section === "edit") {
      const newArr = [...childData.office_catalogue];
      const dataArr = [];
      newArr.forEach((item) => {
        if (item) {
          const data = {
            url: item.url,
          };
          dataArr.push(data);
        }
      });
      setFileList(dataArr);
      form.setFieldsValue({
        ...childData,
        office_category: childData.office_category,
        region_id: childData.region_id,
        name: childData.name,
        description: childData.description,
        service_name: childData.office_details.service_name,
        location: childData.office_details.location,
        address: childData.office_details.address,
        capacity_chair: childData.office_details.capacity_chair,
        capacity_desk: childData.office_details.capacity_desk,
        capacity_person: childData.office_details.capacity_person,
        price_per_hour: childData.price.price_per_hour,
        price_daily: childData.price.price_daily,
        price_half_day: childData.price.price_half_day,
        price_weekly: childData.price.price_weekly,
        price_monthly: childData.price.price_monthly,
        price_overtime: childData.price.price_overtime || childData.price.price_per_hour_overtime,
        price_per_hour_external: childData.price.price_per_hour_external,
        price_half_day_external: childData.price.price_half_day_external,
        price_per_day_external: childData.price.price_per_day_external,
        price_per_hour_overtime_external: childData.price.price_per_hour_overtime_external,
        status: childData.status,
        office_catalogue: dataArr,
      });

      setStatusSwitchChecked(childData.status);
      setCoordinates({
        lat: childData.lat,
        lng: childData.lng,
      });
    }
  }, []);

  const handleStatusChange = (checked) => {
    setStatusSwitchChecked(checked);
    form.setFieldsValue({ status: checked });
  };

  const normFile = (e) => {
    return e?.fileList;
  };

  // set input
  const [inputPricePerHour, setInputPricePerHour] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_per_hour !== undefined
        ? childData.price.price_per_hour
        : "",
  });

  const [inputPriceDaily, setInputPriceDaily] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_daily !== undefined
        ? childData.price.price_daily
        : "",
  });
  // const [inputPriceMinimum, setInputPriceMinimum] = useState({
  //   status: "success",
  //   message: "",
  //   value:
  //     childData.price && childData.price.price_minimum !== undefined
  //       ? childData.price.price_minimum
  //       : "",
  // });
  const [inputPriceWeekly, setInputPriceWeekly] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_weekly !== undefined
        ? childData.price.price_weekly
        : "",
  });
  const [inputPriceMonthly, setInputPriceMonthly] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_monthly !== undefined
        ? childData.price.price_monthly
        : "",
  });
  const [inputPriceOvertime, setInputPriceOvertime] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_overtime !== undefined
        ? childData.price.price_overtime
        : "",
  });
  const [inputPricePerHourExternal, setInputPricePerHourExternal] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_per_hour_external !== undefined
        ? childData.price.price_per_hour_external
        : "",
  });
  const [inputPriceHalfDayExternal, setInputPriceHalfDayExternal] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_half_day_external !== undefined
        ? childData.price.price_half_day_external
        : "",
  });
  const [inputPricePerDayExternal, setInputPricePerDayExternal] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_per_day_external !== undefined
        ? childData.price.price_per_day_external
        : "",
  });
  const [inputPricePerHourOvertimeExternal, setInputPricePerHourOvertimeExternal] = useState({
    status: "success",
    message: "",
    value:
      childData.price && childData.price.price_per_hour_overtime_external !== undefined
        ? childData.price.price_per_hour_overtime_external
        : "",
  });
  const [inputCapacityChair, setInputCapacityChair] = useState({
    status: "success",
    message: "",
    value:
      childData.office_details && childData.office_details.capacity_chair !== undefined
        ? childData.office_details.capacity_chair
        : "",
  });
  const [inputCapacityDesk, setInputCapacityDesk] = useState({
    status: "success",
    message: "",
    value:
      childData.office_details && childData.office_details.capacity_desk !== undefined
        ? childData.office_details.capacity_desk
        : "",
  });
  const [inputCapacityPerson, setInputCapacityPerson] = useState({
    status: "success",
    message: "",
    value:
      childData.office_details && childData.office_details.capacity_person !== undefined
        ? childData.office_details.capacity_person
        : "",
  });

  const navigate = useNavigate();
  const onFinish = (values) => {
    const {
      name,
      office_category,
      description,
      status,
      region_id,
      service_name,
      location,
      address,
      price_per_hour,
      price_daily,
      price_weekly,
      price_monthly,
      price_overtime,
      price_per_hour_overtime,
      price_per_hour_external,
      price_half_day_external,
      price_per_day_external,
      price_per_hour_overtime_external,
      capacity_chair,
      capacity_desk,
      capacity_person,
    } = values;

    let result = {
      name,
      office_category: parseInt(office_category),
      description,
      status,
      region_id: parseInt(region_id),
      location,
      service_name,
      address,
      office_catalogue: fileList,
      lat: coordinates.lat,
      lng: coordinates.lng,
      price_per_hour: parseInt(price_per_hour) || 0,
      price_daily: parseInt(price_daily) || 0,
      price_weekly: parseInt(price_weekly) || 0,
      price_monthly: parseInt(price_monthly) || 0,
      price_overtime: parseInt(price_overtime || price_per_hour_overtime) || 0,
      price_per_hour_external: parseInt(price_per_hour_external) || 0,
      price_half_day_external: parseInt(price_half_day_external) || 0,
      price_per_day_external: parseInt(price_per_day_external) || 0,
      price_per_hour_overtime_external: parseInt(price_per_hour_overtime_external) || 0,
      capacity_chair: parseInt(capacity_chair),
      capacity_desk: parseInt(capacity_desk),
      capacity_person: parseInt(capacity_person),
    };
    console.log("onfinish", isLoading);
    console.log("values", values);

    setIsLoading(true);

    if (section === "edit") {
      console.log("form edit", result);
      putOffice(childData.id, result)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success",
              description: "Successfully change the product!",
            });
            setSection("default");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (section === "add") {
      console.log("form add", result);
      postOffice(result, navigate)
        .then((res) => {
          if (res) {
            notification.success({
              message: "Success",
              description: "Successfully added office/service!",
            });
            setSection("default");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleInputNumber = (event, setInputAmount, setPriceFunction, fieldName) => {
    const inputValue = event.target.value.replace(/[^\d]/g, "");
    const numberValue = inputValue === "" ? "" : parseInt(inputValue, 10);

    setInputAmount({
      status: "success",
      message: "",
      value: numberValue,
    });
    setPriceFunction(numberValue);
    form.setFieldsValue({ [fieldName]: numberValue });
  };

  const formatIDR = (value) => {
    if (!value) return "";
    const number = parseFloat(value.replace(/[^\d]/g, ""));
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handlePriceInput = (e, setPrice, fieldName) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^\d]/g, "");

    if (numericValue === "") {
      setPrice("");
      form.setFieldsValue({ [fieldName]: "" });
    } else {
      const formattedValue = formatIDR(numericValue);
      setPrice(formattedValue);
      form.setFieldsValue({ [fieldName]: numericValue });
    }
  };

  return (
    <div className="content-section">
      <div className="breadcrumb mb-4 text-md">
        <Link to="/" className="text-gray-500">
          Dashboard
        </Link>
        <span className="mx-2">&gt;</span>
        <Link to="#" onClick={() => setSection("default")} className="text-gray-500">
          Office List
        </Link>
        {section !== "default" && (
          <>
            <span className="mx-2">&gt;</span>
            <span>{section === "add" ? "Add" : section === "view" ? "Detail" : "Edit"}</span>
          </>
        )}
      </div>
      <div className="header">
        <div className="flex items-center">
          <div className="header-title lg:text-[28px] text-[20px] flex items-center">
            {section === "add" && "Add Office"}
            {section === "view" && "Detail"}
            {section === "edit" && "Edit Office/Service"}
          </div>
        </div>
      </div>
      <div className="body max-body-height pt-3 pb-0">
        {section !== "view" ? (
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateMessages={validateMessage}
          >
            <div className="relative scroll-custom">
              <div ref={DataRef} className="fit-scroll py-2">
                <div className="flex justify-between items-center">
                  <div className="text-lg font-bold text-baseColor mb-2">Office Information</div>
                  <Form.Item
                    name="status"
                    valuePropName="checked"
                    initialValue={false}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <div className="flex items-center justify-between space-x-2">
                      <span>Active</span>
                      <Switch
                        onChange={(value) => {
                          form.setFieldsValue({ status: value });
                        }}
                      />
                    </div>
                  </Form.Item>
                </div>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Office Name", true)}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Office Name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Office Name" />
                    </Form.Item>
                    <Form.Item
                      label={getLabel("Office Region", true)}
                      name="region_id"
                      rules={[
                        {
                          required: true,

                          message: "Please select the Office Region",
                        },
                      ]}
                    >
                      <Select placeholder="Select Region">
                        {regionID.map((item) => (
                          <Option value={item.regionID} key={item.regionID}>
                            {item.regionName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={getLabel("Office Category", true)}
                      name="office_category"
                      rules={[
                        {
                          required: true,
                          message: "Please select the Office Category",
                        },
                      ]}
                    >
                      <Select placeholder="Select Category">
                        {categoryID.map((item) => (
                          <Option value={item.categoryID} key={item.categoryID}>
                            {item.categoryName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={getLabel("Phone Number", true)}
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        className="mt-3"
                        placeholder="Enter Phone Number"
                        type="tel"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <div ref={DescriptionRef} className="fit-scroll" style={{ height: "100%" }}>
                      <Row style={{ height: "100%" }}>
                        <Col span={24} style={{ height: "100%" }}>
                          <Form.Item
                            label="Office Description"
                            name="description"
                            style={{ height: "100%" }}
                          >
                            <TextArea
                              rows={12}
                              style={{ width: "100%", height: "100%" }}
                              placeholder="Write a brief description of the Office/Service..."
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <OfficeImageUpload fileList={fileList} setFileList={setFileList} />

                <Divider />

                <Row gutter={16} className="mt-4">
                  <Col span={24}>
                    <div className="text-lg font-bold text-baseColor mb-2">Location</div>
                    <Form.Item name="coordinates">
                      <Map setCoordinates={setCoordinates} initialCoordinates={coordinates} />
                    </Form.Item>
                    <Form.Item
                      label={getLabel("Office Address")}
                      name="address"
                      rules={[
                        {
                          // required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Complete Address" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div ref={OfficeDetailRef} className="fit-scroll py-2">
                <div className="text-lg font-bold text-baseColor mb-2">Office Detail</div>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Service Name")}
                      name="service_name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Service Name" />
                    </Form.Item>
                    <Form.Item
                      label={getLabel("Capacity (Desk)", true)}
                      name="capacity_desk"
                      validateStatus={inputCapacityDesk.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the desk capacity",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          handleInputNumber(
                            e,
                            setInputCapacityDesk,
                            setCapacityDesk,
                            "capacity_desk",
                          )
                        }
                        value={inputCapacityDesk.value}
                        placeholder="Insert desk capacity (e.g. 5)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Capacity (Chair)", true)}
                      name="capacity_chair"
                      validateStatus={inputCapacityChair.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the chair capacity",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          handleInputNumber(
                            e,
                            setInputCapacityChair,
                            setCapacityChair,
                            "capacity_chair",
                          )
                        }
                        value={inputCapacityChair.value}
                        placeholder="Insert chair capacity (e.g. 5)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={getLabel("Capacity (Person)", true)}
                      name="capacity_person"
                      validateStatus={inputCapacityPerson.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the person capacity",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) =>
                          handleInputNumber(
                            e,
                            setInputCapacityPerson,
                            setCapacityPerson,
                            "capacity_person",
                          )
                        }
                        value={
                          capacityPerson === null ||
                            capacityPerson === undefined ||
                            capacityPerson === 0
                            ? 0
                            : capacityPerson
                        }
                        id="capacity_person"
                        placeholder="Insert person capacity (e.g. 5)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div ref={PriceRef} className="fit-scroll py-2">
                <div className="text-lg font-bold text-baseColor mb-2">
                  Price (User with Accounts)
                </div>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/hour", true)}
                      name="price_per_hour"
                      validateStatus={inputPricePerHour.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per hour",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) => handlePriceInput(e, setPricePerHour, "price_per_hour")}
                        value={pricePerHour}
                        id="price_per_hour"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/day", true)}
                      name="price_daily"
                      validateStatus={inputPriceDaily.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per day",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) => handlePriceInput(e, setPriceDaily, "price_daily")}
                        value={priceDaily}
                        id="price_daily"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/week", true)}
                      name="price_weekly"
                      validateStatus={inputPriceWeekly.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per week",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) => handlePriceInput(e, setPriceWeekly, "price_weekly")}
                        value={formatRupiah(priceWeekly)}
                        id="price_weekly"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/month", true)}
                      name="price_monthly"
                      validateStatus={inputPriceMonthly.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per month",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) => handlePriceInput(e, setPriceMonthly, "price_monthly")}
                        value={formatRupiah(priceMonthly)}
                        id="price_monthly"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price Overtime (per hour)", true)}
                      name="price_overtime"
                      validateStatus={inputPriceOvertime.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per overtime",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) => handlePriceInput(e, setPriceOvertime, "price_overtime")}
                        value={formatRupiah(priceOvertime)}
                        id="price_overtime"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={12}>
    <Form.Item
      label={getLabel("Minimum Price", true)}
      validateStatus={inputPriceMinimum.status}
      help={inputPriceMinimum.message}
      rules={[
        {
          required: true,
        },
      ]}
      initialValue={0}
    >
      <Input
        addonBefore="IDR"
        onChange={(e) =>
          handleInputNumber(e, setInputPriceMinimum, setPriceMinimum)
        }
        value={formatRupiah(priceMinimum)}
        id="price_minimum"
        placeholder="0"
      />
    </Form.Item>
  </Col> */}
                </Row>
              </div>
              <div ref={PriceExternalRef} className="fit-scroll py-2">
                <div className="text-lg font-bold text-baseColor mb-2">Price (External)</div>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/hour", true)}
                      name="price_per_hour_external"
                      validateStatus={inputPricePerHourExternal.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per hour",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) =>
                          handlePriceInput(e, setPricePerHourExternal, "price_per_hour_external")
                        }
                        value={formatRupiah(pricePerHourExternal)}
                        id="price_per_hour_external"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/half day", true)}
                      name="price_half_day_external"
                      validateStatus={inputPriceHalfDayExternal.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per half day",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) =>
                          handlePriceInput(e, setPriceHalfDayExternal, "price_half_day_external")
                        }
                        value={formatRupiah(priceHalfDayExternal)}
                        id="price_half_day_external"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/day", true)}
                      name="price_per_day_external"
                      validateStatus={inputPricePerDayExternal.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per day",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) =>
                          handlePriceInput(e, setPricePerDayExternal, "price_per_day_external")
                        }
                        value={formatRupiah(pricePerDayExternal)}
                        id="price_per_day_external"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLabel("Price/Overtime (per hour)", true)}
                      name="price_per_hour_overtime_external"
                      validateStatus={inputPricePerHourOvertimeExternal.status}
                      rules={[
                        {
                          required: true,
                          message: "Please input the price per overtime",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="IDR"
                        onChange={(e) =>
                          handlePriceInput(
                            e,
                            setPricePerHourOvertimeExternal,
                            "price_per_hour_overtime_external",
                          )
                        }
                        value={formatRupiah(pricePerHourOvertimeExternal)}
                        id="price_per_hour_overtime_external"
                        placeholder="Enter price"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="-mx-5">
              <div className="flex justify-end gap-3 px-5 pt-4">
                <Buttons onClick={() => setSection("default")}>Cancel</Buttons>
                <Form.Item type="button">
                  <Buttons type="primary" htmlType="submit" loading={isLoading}>
                    Save
                  </Buttons>
                </Form.Item>
              </div>
            </div>
          </Form>
        ) : (
          <OfficeDetail {...props} DataRef={DataRef} DescriptionRef={DescriptionRef} />
        )}
      </div>
    </div>
  );
}
