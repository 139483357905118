import { SET_USER_DATA } from "./types";

const initialState = {
  user: {
    token: null,
  },
  isLoggedIn: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        user: action.payload.value,
        isLoggedIn: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
